import React from "react";

const UsageTips = ({ tips }) => {
  return (
    <div className="flex items-center justify-center w-full">
    <div className="usage-tips-container w-[90%] p-6">

      <h2 className="usage-tips-heading text-2xl p-2 md:text-4xl font-bold text-[#0A6075] mb-6">
        Usage <b className="text-black">Tips and Tricks </b> 
      </h2>

      {/* Responsive Flex Container */}
      <div className="flex flex-col items-center justify-center gap-6">
        {tips.map((tip, index) => (
          <div
            key={index}
            className="relative overflow-visible tip-item my-2 p-6 border rounded-lg shadow-md bg-[#F9F9F9] flex-1 w-full transition-all"
          >
            {/* Tip Title with Background Overlap */}
            <h3 className="absolute -top-4 left-4 bg-[#0A6075] text-white px-4 py-2 rounded-md shadow-md">
              {tip.title}
            </h3>

            {/* Tip Description */}
            <p className="tip-description mt-8 text-gray-700 text-sm">
              {tip.description}
            </p>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default UsageTips;
