import api from "../Service/apiHelper";

// ####################################
export const searchSuggestion = async (searchQuery) => {
  try {
    const response = await api.post(
      `api/v1/feature/v11Suggestion?query=${searchQuery}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};
export const DynamicSearch = async (searchQuery) => {
  try {
    const response = await api.post(
      `api/v1/feature/search?query=${searchQuery}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};

// ###########################################################################################

export const productSuggestion = async (query) => {
  try {
    const response = await api.post(
      `api/v1/feature/search_suggestion?query=${query}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};

// ##############################################################################

export const newArrival_Api = async (query) => {
  try {
    const response = await api.get(`api/v1/product/newarrival`);
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};
// ################################### top_Mobiles #############################
export const top_Mobiles = async (query) => {
  try {
    const response = await api.get(`api/v1/product/top_mobiles`);
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};
// #####################################  fetchAllData  #########################
export const fetchAllData = async (params) => {
  const queryString = new URLSearchParams(params).toString();

  try {
    const response = await api.post(
      `api/v1/feature/fetchAllData?${queryString}`
    );
    //  console.log(response.data);
    return response.data;
   
    
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
  
};

// ###########################################################################################
export const Top_Mobiles_Brands = async (brand) => {
  try {
    const response = await api.get(
      `api/v1/product/Top_Mobiles_Brands?brand=${brand}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};

// ################################### top_Brands ###########################################
// ###########################################################################################
export const filterkey = async (category) => {
  try {
    const response = await api.post(
      `api/v1/feature/filterkey?category=${category}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
};

export const fetchfilterAllData = async (query) => {
  try {
    const response = await api.post(`api/v1/feature/fetchAllData`, null, {
      params: query,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
};

export const distinctCategories = async (query) => {
  try {
    const response = await api.post(
      `api/v1/feature/fetchCategory?brand=${query}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
};

export const topBrand = async (query) => {
  try {
    const response = await api.post(`api/v1/feature/topBrand`, null, {
      params: query,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
};
// ####################################
export const CompareSearch = async (category, modelName) => {
  try {
    const response = await api.post(`api/v1/feature/comparetes`, category, {
      params: modelName,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
};
