import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { categoryData } from "../../Pages/SpecificationData";

export default function TroubleSection({ category, brand, name, img, id }) {
  const data = categoryData.find(
    (item) =>
      item.category.toUpperCase().includes(category.toUpperCase()) &&
      item.brand.toUpperCase() === brand.toUpperCase()
  );

  if (!data) {
    return (
      <div className="container p-3">
        <h2 className="text-center">No Troubleshooting Data Available</h2>
      </div>
    );
  }

  const renderContent = (content) => {
    const lines = content.split("\n").filter((line) => line.trim() !== "");
    return lines.map((line, index) => {
      if (/^\d+\./.test(line.trim())) {
        const firstChar = line.trim().charAt(0); // Get the first letter after "1. "
        return (
          <li key={index} className="flex gap-3 mb-2 items-start">
            <div className="bg-[#0A6075] text-white rounded-full w-6 h-6 flex items-center justify-center font-bold">
              {firstChar}
            </div>
            <p className="text-sm">{line.trim()}</p>
          </li>
        );
      }
      return (
        <p key={index} className="text-sm mb-2">
          {line.trim()}
        </p>
      );
    });
  };

  return (
    <div className="container mx-auto p-5 bg-white border-1 border-gray-200 rounded-xl shadow-lg">
      <h1 className="text-2xl sm:text-4xl font-bold text-start mb-6">
        Troubleshooting Steps For{" "}
        <b className="text-[#0A6075]">
          {brand} {name}
        </b>
      </h1>

      <div className="flex flex-col md:flex-row gap-6">
        {/* Left Section - Steps */}
        <div
          className="flex-1  overflow-y-auto max-h-[400px] pr-4
            scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
        >
          <h3 className="text-xl font-semibold mb-4">Troubleshooting Steps:</h3>
          <ul className="space-y-4 text-black">{renderContent(data.troubleshoot)}</ul>

          <h3 className="text-xl font-semibold mt-6 mb-4">
            Printer Installation Steps:
          </h3>
          <ul className="space-y-4 text-black">{renderContent(data.installation)}</ul>
          <h3 className="text-xl font-semibold mt-6 mb-4">
            Printer Compatibility Information:
          </h3>
          <ul className="space-y-4 text-black">{renderContent(data.compatibility)}</ul>
        </div>

        {/* Right Section - Image */}
        <div className="flex-1 flex items-center justify-center">
          <div className="bg-[#edf7fa] border border-[#0A6075] rounded-xl p-4">
            <img
              src={img}
              alt={name}
              className="w-[90%] h-[300px] object-contain rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

TroubleSection.propTypes = {
  category: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  name: PropTypes.string,
  img: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
