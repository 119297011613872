import api from "../Service/apiHelper";
// ###########################################################################################

export const DynamicSearch = async (searchQuery) => {
  try {
    const response = await api.post(
      `api/v1/feature/search?query=${searchQuery}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};
// ###########################################################################################
export const productSuggestion = async (query) => {
  try {
    const response = await api.post(
      `api/v1/feature/search_suggestion?query=${query}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};

// ##############################################################################

export const newArrival_Api = async (query) => {
  try {
    const response = await api.get(`api/v1/product/newarrival`);
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};
// ##############################################################################
export const top_Mobiles = async (query) => {
  try {
    const response = await api.get(`api/v1/product/top_mobiles`);
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};
// ###########################################################################################
export const top_Brands = async ({ brand, category }) => {
  const queryParams = {};
  // Add brand to query parameters if provided
  if (brand) {
    queryParams.brand = brand;
  }
  // Add category to query parameters if provided
  if (category) {
    queryParams.category = category;
  }
  try {
    const response = await api.get(`api/v1/product/top_Brands`, {
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};

// ###########################################################################################
export const Top_Mobiles_Brands = async (brand) => {
  try {
    const response = await api.get(
      `api/v1/product/Top_Mobiles_Brands?brand=${brand}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};

// ################################### top_Brands ###########################################
export const Top_BrandsforHome = async (brand) => {
  try {
    const response = await api.post(`api/v1/product/top-brands?brand=${brand}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching top brand results:", error);
    throw new Error("An error occurred while fetching top brand results.");
  }
};
