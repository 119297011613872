import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Haeding";
import Product from "../Products/Product";
import SampleNextArrow from "../NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../NewArrivals/SamplePrevArrow";
import { top_Mobiles } from "../../../Service/homeService";

const NewArrivals = () => {
  const [newdata, Setnewdata] = useState([]);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const fetchData = async () => {
    const response = await top_Mobiles();
    Setnewdata(response.data);
    // console.log("top mobile ",response);
    
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getFirstFiveWords = (str) => {
    const words = str.split(" ");
    return words.slice(0, 6).join(" ");
  };

  return (
    <div className="w-full pb-16 mt-5 mb-5 p-5">
      <Heading heading="Top Mobiles" />
      <Slider {...settings}>
        {newdata?.map((item) => (
          <div key={item._id} className="px-2">
            <Product
              _id={item._id}
              img={item.img}
              productName={getFirstFiveWords(item.model_name)}
              price="44.00"
              category={item.category}
              slug={item.slug}
              brand={item.brand}
              badge={true}
              des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NewArrivals;
