import React, { useState } from "react";
import { FaTv, FaMobileAlt, FaTabletAlt, FaLaptop, FaGamepad, FaWifi, FaPrint, FaHeadphones, FaExternalLinkAlt, FaSdCard, FaMouse, FaVrCardboard, FaKeyboard, FaUserLock } from "react-icons/fa"; 
import { FaComputer } from "react-icons/fa6";
import { GiChipsBag, GiProcessor, GiSpeaker, GiSunglasses, GiWatch } from "react-icons/gi";

const categories = [
   { id: 1, name: "Smart TV", icon: <FaTv /> },
   { id: 2, name: "mobiles", icon: <FaMobileAlt /> },
   { id: 4, name: "Router", icon: <FaWifi /> },
   { id: 5, name: "Printer", icon: <FaPrint /> },
   { id: 6, name: "Earphones", icon: <FaHeadphones /> },
   { id: 3, name: "Tablets", icon: <FaTabletAlt /> },
  { id: 7, name: "Extenders", icon: <FaExternalLinkAlt /> },
  { id: 8, name: "Laptops", icon: <FaLaptop /> },
  { id: 9, name: "Graphic Card", icon: <FaSdCard /> },
  { id: 10, name: "Motherboard", icon: <GiChipsBag /> },
   { id: 11, name: "Processors", icon: <GiProcessor /> },
  { id: 11, name: "Computers", icon: <FaComputer /> },
  { id: 11, name: "gaming mouses", icon: <FaMouse /> },
  { id: 11, name: "Smart Glass", icon: <GiSunglasses /> },
  { id: 11, name: "Gaming Consoles", icon: <FaGamepad /> },
  { id: 11, name: "Speakers", icon: <GiSpeaker /> },
  { id: 11, name: "VR Headset", icon: <FaVrCardboard /> },
  { id: 11, name: "gaming keyboard", icon: <FaKeyboard /> },
  { id: 11, name: "Smart Watch", icon: <GiWatch /> },
  { id: 11, name: "Firewall", icon: <FaUserLock /> },
];

const CategorySelector = ({ onCategorySelect, handleDelete }) => {
  const [selectedProduct, setSelectedProduct] = useState(""); // Store only one selected product

  // Handle checkbox change
  const handleCheckboxChange = (name) => {
    setSelectedProduct((prevSelected) => (prevSelected === name ? "" : name)); // Set the clicked product as selected
    const newSelected = selectedProduct === name ? "" : name;
    onCategorySelect(newSelected);
    
  };

  return (
    <div className="my-5">
      <h3 className="Heading-com text-center mb-4">
        Confused? Compare Your Products Now..
      </h3>
      <div className="w-[90%] mx-auto p-6">
      <h2 className="text-[#0a6075] font-bold text-2xl my-2 p-1"> Select Your Category </h2>
        {/* Checkbox List */}
        <div className="flex p-3 flex-wrap justify-start items-center gap-3 bg-white shadow-md border-gray-600 border rounded-md">
          {categories.map((category,index) => (
            // <div key={product} className="flex  items-center">
            //   <label
            //     htmlFor={product}
            //     className=" rounded-md border-1 bg-[#5f9aa930] text-[#0a6075] border-[#0a6075] p-1 w-[11.3rem] text-lg"
            //   >
            //     {product}
            //     <input
            //       type="checkbox"
            //       id={product}
            //       className="m-1"
            //       checked={selectedProduct === product} // Ensure only one is selected
            //       onChange={() => handleCheckboxChange(product)}
            //     />
            //   </label>
            // </div>
            <div
              key={index}
              className={`cursor-pointer rounded-md border-2 h-[110px] w-[110px] p-1 m-2 text-md text-center flex flex-col items-center justify-center transition-all  ${
                selectedProduct === category.name
                  ? "bg-[#0a6075] text-white"
                  : "bg-[#5f9aa930] text-[#0a6075] border-[#0a6075]"
              }`}
              onClick={() => handleCheckboxChange(category.name)}
            >
              {category.icon && (
                <span className="text-3xl mb-2">{category.icon}</span>
              )}
              <span>{category.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategorySelector;
