import React from "react";
import { Link } from "react-router-dom";

const Footer2 = () => {
  return (
    <footer
      className="bg-gray-100 text-gray-600 flex flex-col justify-center items-center text-sm py-4 border-t border"
      //   style={{ border: "1px solid red" }}
    >
      <div
        className="w-[98%] mx-auto px-1"
        // style={{ border: "1px solid red" }}
      >
        {/* First row with main links */}
        <div className="flex flex-wrap flex-row justify-between items-center space-y-4 md:space-y-0">
          {/* Navigation links */}
          <div
            className="flex flex-wrap items-start text-start justify-center md:justify-start space-x-4 w-[60%]"
            // style={{ border: "1px solid red" }}
          >
            <Link to="/" className="hover:underline">
              Home
            </Link>
            <Link to="/comparison" className="hover:underline">
              Compare Mobile Phones
            </Link>
            <Link to="/mobiles" className="hover:underline">
              Latest Mobile Phones
            </Link>
            <Link to="/mobilefinder" className="hover:underline">
              Mobile Finder
            </Link>
            <Link to="/mobiles/samsung" className="hover:underline">
              Samsung Phones
            </Link>
            <Link to="/mobiles/apple" className="hover:underline">
              Apple Phones
            </Link>
            <Link to="/mobiles/xiaomi" className="hover:underline">
              Xiaomi Phones
            </Link>
            <Link to="/smart-tv" className="hover:underline">
              Latest Tv Products
            </Link>
          </div>

          {/* Social icons */}
          <div className="flex flex-wrap w-[40%] justify-center space-x-4">
            <a
              href="https://www.youtube.com/@gadgetfobia"
              className="hover:text-gray-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              href="https://www.facebook.com/people/Gadgetfobia/61560425224541/?mibextid=ZbWKwL"
              className="hover:text-gray-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://twitter.com"
              className="hover:text-gray-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/gadgetfobia/"
              className="hover:text-gray-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>

        {/* Second row with copyright and other links */}
        <div
          className="mt-4 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0"
          //   style={{ border: "1px solid red" }}
        >
          <div className="flex flex-wrap justify-center md:justify-start space-x-4">
            <Link to="/laptops" className="hover:underline">
              Latest Laptops
            </Link>
            <Link to="/speakers" className="hover:underline">
              Latest Speakers
            </Link>
            <Link to="/tools" className="hover:underline"></Link>
            <Link to="/printer" className="hover:underline">
              Latest Printer
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer2;

