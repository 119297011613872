// TrackPageView.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-LB50GBN471", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null; // This component doesn't render any UI
};

export default TrackPageView;

// Optional Enhancement: Track Initial Page View
// If you want to ensure the initial page load (when the app mounts) is also tracked, add the following logic inside TrackPageView:


// useEffect(() => {
//   if (window.gtag) {
//     window.gtag("config", "G-LB50GBN471", {
//       page_path: location.pathname,
//     });
//   }
// }, []);  // Empty dependency array to run only once on mount
