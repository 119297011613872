import React, { Component } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedIndex: null, // Track the currently expanded FAQ
    };
  }

  handleToggle = (index) => {
    this.setState((prevState) => ({
      expandedIndex: prevState.expandedIndex === index ? null : index,
    }));
  };

  render() {
    const { category, faqs } = this.props;
    const { expandedIndex } = this.state;

    if (!faqs || faqs.length === 0) {
      return (
        <div className="faq-container text-center text-gray-500 py-10">
          No FAQs available for this category.
        </div>
      );
    }

    const formattedCategory = category.toUpperCase().replace("_", "");
    const filteredFaqs = faqs.filter(
      (faq) => faq.category.toUpperCase().replace("_", "") === formattedCategory
    );

    if (filteredFaqs.length === 0) {
      return (
        <div className="faq-container text-center text-gray-500 py-10">
          No FAQs available for this category.
        </div>
      );
    }

    // Split FAQs into two separate columns for layout purposes
    const halfLength = Math.ceil(filteredFaqs.length / 2);
    const column1Faqs = filteredFaqs.slice(0, halfLength);
    const column2Faqs = filteredFaqs.slice(halfLength);

    return (
      <div className="w-full my-5 flex flex-col items-center justify-center">
        {/* FAQ Header */}
        <div className="w-[90%] lg:w-[75%] flex items-start justify-start">
          <h1 className="sm:text-4xl font-bold text-2xl py-2">
            {formattedCategory} <b className="text-[#0A6075]">FAQs</b>
          </h1>
        </div>

        {/* Two Columns of FAQ Items */}
        <div className="w-[90%] lg:w-[85%] flex flex-wrap justify-between gap-6">
          {/* Column 1 */}
          <div className="flex flex-col w-full md:w-[48%]">
            {column1Faqs.map((faq, index) => (
              <div
                key={index}
                className={`rounded-md border border-gray-300 shadow-sm transition-all duration-300 p-4 cursor-pointer mb-4 ${
                  expandedIndex === index
                    ? "bg-[#ECF9FB] border-[#0A6075]"
                    : "bg-white"
                }`}
                onClick={() => this.handleToggle(index)}
              >
                <div className="flex justify-between items-center">
                  <p className="text-base font-semibold text-[#0A6075]">
                    {faq.question}
                  </p>
                  <span className="text-xl text-[#0A6075]">
                    {expandedIndex === index ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </div>
                {expandedIndex === index && (
                  <div className="pt-2 text-sm text-gray-600">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Column 2 */}
          <div className="flex flex-col w-full md:w-[48%]">
            {column2Faqs.map((faq, index) => (
              <div
                key={index + halfLength} // Ensure unique keys between columns
                className={`rounded-md border border-gray-300 shadow-sm transition-all duration-300 p-4 cursor-pointer mb-4 ${
                  expandedIndex === index + halfLength
                    ? "bg-[#ECF9FB] border-[#0A6075]"
                    : "bg-white"
                }`}
                onClick={() => this.handleToggle(index + halfLength)}
              >
                <div className="flex justify-between items-center">
                  <p className="text-base font-semibold text-[#0A6075]">
                    {faq.question}
                  </p>
                  <span className="text-xl text-[#0A6075]">
                    {expandedIndex === index + halfLength ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </span>
                </div>
                {expandedIndex === index + halfLength && (
                  <div className="pt-2 text-sm text-gray-600">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
