import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCompare, addToCompare } from "../store/compareActions";
import { useNavigate } from "react-router-dom";
import ComparisonSearch from "../ComparisonSearch";
import ComparisonModal from "./ComparisonModal";

const ComparisonPopup = ({ popup }) => {
  const close = () => {
    popup();
  };

  return (
    <div className="compare-popup">
      <span className="close" onClick={close}>
        &times;
      </span>
      <ComparisonModal />
    </div>
  );
};

export default ComparisonPopup;
