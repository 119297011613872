// import React, { useRef, useEffect, useState } from "react";

// export default function ProductDetailModal({ name, readmore }) {
//   const [showModal, setShowModal] = useState(false);
//   const modalRef = useRef(null);

//   // Close modal if clicked outside
//   const handleClickOutside = (event) => {
//     if (modalRef.current && !modalRef.current.contains(event.target)) {
//       setShowModal(false);
//     }
//   };

//   useEffect(() => {
//     if (showModal) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     // Cleanup the event listener on component unmount
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [showModal]);

//   return (
//     <>
//       <button
//         className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
//         type="button"
//         onClick={() => setShowModal(true)}
//       >
//         Get Full Info
//       </button>

//       {showModal && (
//         <>
//           <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//             <div
//               className="relative w-auto my-6 mx-auto max-w-5xl mt-40 h-[550px]"
//               ref={modalRef}
//             >
//               {/* Content */}
//               <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
//                 {/* Header */}
//                 <div className="flex items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded-t m-auto">
//                   <h3 className="text-base font-semibold">{name}</h3>
//                   <button
//                     className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
//                     onClick={() => setShowModal(false)}
//                   >
//                     <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
//                       ×
//                     </span>
//                   </button>
//                 </div>
//                 {/* Body with Scroll */}
//                 <div className="relative p-6 flex-auto max-h-96 overflow-y-auto">
//                   <div
//                     className="prose max-w-none text-blueGray-500 leading-relaxed"
//                     dangerouslySetInnerHTML={{ __html: readmore }} // Render HTML content here
//                   />
//                 </div>

//                 {/* Footer */}
//                 <div className="flex items-center justify-end p-3 border-t border-solid border-blueGray-200 rounded-b">
//                   <button
//                     className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
//                     type="button"
//                     onClick={() => setShowModal(false)}
//                   >
//                     Close
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//         </>
//       )}
//     </>
//   );
// }

import React, { useRef, useEffect, useState } from "react";
import { FaWindowClose } from "react-icons/fa";

export default function ProductDetailModal({ name, readmore }) {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  // Close modal if clicked outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
      // Prevent background scrolling when the modal is open
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable background scrolling when the modal is closed
      document.body.style.overflow = "auto";
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto"; // Ensure scrolling is restored on unmount
    };
  }, [showModal]);

  return (
    <div className="inline-block">
      <p
        // className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        className="text-md cursor-pointer font-semibold hover:font-bold"
        onClick={() => setShowModal(true)}
      >
        Read more...
      </p>

      {showModal && (
        <>
          {/* Modal Background */}
          <div className="fixed inset-0 z-40 bg-black bg-opacity-50 "></div>

          {/* Modal Container */}
          <div className="fixed inset-0 z-50 flex items-center justify-center mt-[50px]">
            <div
              className="relative w-auto max-w-5xl mx-auto h-[80vh] bg-white rounded-lg shadow-lg"
              ref={modalRef}
            >
              {/* Modal Content */}
              <div className="flex flex-col justify-around h-full">
                {/* Header */}

                <div className="relative p-3 rounded-lg border-b border-gray-300">
                  {/* Close Button Positioned to Top Right */}
                  <button
                    className="absolute left-2 top-4 text-red-500 font-bold uppercase text-3xl focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <FaWindowClose />
                  </button>

                  {/* Centered Name */}
                  <h3 className="text-lg font-semibold text-center">{name}</h3>
                </div>

                {/* Body - Scrollable Section */}
                <div className="flex-1 overflow-y-auto p-6 rounded-lg">
                  <div
                    className="prose max-w-none text-blueGray-500 leading-relaxed"
                    dangerouslySetInnerHTML={{ __html: readmore }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
