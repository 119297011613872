import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RedirectSpecificUrl = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the current URL path is /laptops/contact-html
    if (location.pathname === "/laptops/contact-html") {
      // Redirect to /laptops
      navigate("/laptops", { replace: true });
    }
  }, [location, navigate]);

  return null; // No need to render anything
};

export default RedirectSpecificUrl;
