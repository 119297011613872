import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Header from "./Components/Home/Navbar/Header";
import HeaderBottom from "./Components/Home/Navbar/HeaderBottem";
import Footer from "./Components/Home/Footer/Footer";
import FooterBottom from "./Components/Home/Footer/FooterBottom";
import MyList from "./Components/Home/Mylist/MyList";
import NewArrivals from "./Components/Home/NewArrivals/NewArrival";
import Brands from "./Components/Home/Brands/Brands";
import Offer from "./Components/Home/SpecialOffer/Offer";
import TopMobiles from "./Components/Home/Products/TopMobiles";
import MobileBrand from "./Components/Home/Brands/MobileBrand";
import SignIn from "./Pages/Account/Signin";
import SignUp from "./Pages/Account/Signup";
import Shop from "./Pages/Shop";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import Dashboard from "./Admin/Dashboard/Dashboard";
import Category from "./Admin/Dashboard/AdminProductCategory";
import ProductList from "./Admin/Dashboard/ProductList";
import Brand from "./Admin/Dashboard/Brand";
import Product from "./Admin/Dashboard/Product";
import SearchResult from "./Feature/SearchResult";
import PrivateRoute from "./Admin/PrivateRoute/PrivateRoute";
import ProductUpdate from "./Admin/Dashboard/ProductUpdate";
import ProductModified from "./Admin/Dashboard/ProductModified";
import AdminLogin from "./Admin/Auth/AdminLogin";
import Wishlist from "./Pages/Wishlist/Wishlist";
import Profile from "./Pages/Account/Profile";
import AdminProfile from "./Admin/Dashboard/AdminProfile";
import SearchBar from "./Components/Banner/SearchBar";
import News from "./Components/Home/News/News";
import FilterKey from "./Components/PageProps/ShopPage/ShopBy/FilterKey";
import Coming from "./Pages/ComingSoon/Coming";
import NewsPage from "./Components/Home/News/NewsPage";
import CategoryPage from "./Pages/CategoryPage";
import ComparisonPage from "./Feature/Compare/ComparisonPage";
import Newsletter from "./Admin/Dashboard/Newsletter";
import UserList from "./Admin/Dashboard/UserList";
import AdminNews from "./Admin/Dashboard/AdminNews";
import AdminNewsUpdate from "./Admin/Dashboard/AdminNewsUpdate";
import NewsDetails from "./Components/Home/News/NewsDetails";
import AdminHC from "./Admin/Dashboard/HomeCategory/AdminHC";
import AdminHCB from "./Admin/Dashboard/HomeCategory/AdminHCB";
import AdminAddHC from "./Admin/Dashboard/HomeCategory/AdminAddHC";
import AdminUpdateHC from "./Admin/Dashboard/HomeCategory/AdminUpdateHC";
import AdminProductCategory from "./Admin/Dashboard/AdminProductCategory";
import AdminAddProduct from "./Admin/Dashboard/AdminAddProduct";
import Blog from "./Components/blog/Blog";
import TSPage from "./Components/Troubleshoot/TSPage";
import Trending from "./Pages/trending/Trending";
import TrendingDetails from "./Pages/trending/TrendingDetails";
import About from "./Components/About/About";
import BlogTest from "./Components/blog/BlogTest";
import BrandPage from "./Components/Home/Brands/BrandPage";
import RedirectSpecificUrl from "./Components/navigator/RedirectSpecificUrl";
import Footer2 from "./Components/Home/Footer/Footer2";
import GoogleTagManager from "./Components/GoogleTagManager/GoogleTagManager";
import TrackPageView from "./Components/GoogleTagManager/TrackPageView";
import Navbar from "./Components/Home/Navbar/Navbar";
import NavWithSearch from "./Components/Home/Navbar/NavWithSearch";
import HomeGrid from "./Components/homeGrid/HomeGrid";
import CategoryGrid from "./Components/Home/Mylist/CategoryGrid";
import PopularGadgets from "./Components/Home/popularGadgets/PopularGadgets";
import PopularPhones from "./Components/Home/popularPhones/PopularPhones";
import ViewPopularGadgets from "./Pages/ViewAll/ViewPopularGadgets";
import ViewPopularPhones from "./Pages/ViewAll/ViewPopularPhones";
import MobileFinder from "./Pages/Mobile-finder/MobileFinder";
import HeaderBottem from "./Components/Home/Navbar/HeaderBottem";
import { Provider } from 'react-redux';
import NewComparePage from "./Feature/Compare/ComparePageAddItem/NewComparePage";

const toLowerCaseUrl = (url) => {
  const [path, queryHash] = url.split(/[?#]/);

  const sanitizedPath = path
    .toLowerCase()
    .replace(/%20/g, "-") // Replace %20 with hyphens
    .replace(/[^\w\-\/]/g, "-"); // Replace special characters except slashes and hyphens

  const sanitizedQueryHash = queryHash
    ? queryHash.toLowerCase().replace(/[^\w\-=&]/g, "-") // Keep '=', '&' characters for query strings
    : ""; // Sanitize query or hash

  return sanitizedQueryHash
    ? `${sanitizedPath}?${sanitizedQueryHash}`
    : sanitizedPath;
};

// LowercaseUrlWrapper Component

const LowercaseUrlWrapper = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = location.pathname + location.search + location.hash;
    const lowercasedUrl = toLowerCaseUrl(currentUrl);
    if (currentUrl !== lowercasedUrl) {
      navigate(lowercasedUrl, { replace: true });
    }
  }, [location, navigate]);

  return children;
};

const Layout = () => {
  return (
    <div className="w-full mx-auto " style={{ overflow: "hidden" }}>
      <HomeGrid />
      <CategoryGrid />
      {/* <div className="p-5">
        <NewArrivals />
      </div> */}
      <PopularGadgets />
      <Brands />
      {/* <Offer /> */}
      <PopularPhones />
      {/* <TopMobiles /> */}
      <MobileBrand />
      <News />
    </div>
  );
};

const SharedLayout = () => {
  const handleSearch = (searchTerm) => {};
  return (
    <div>
      <Navbar />
      {/* <Header /> */}
      {/* <TrackPageView /> Track page views for all routes */}
      <RedirectSpecificUrl />
      {/* Include the redirection logic */}

      {/* <HeaderBottom /> */}
      <div className="my-3">
        <NavWithSearch onSearch={handleSearch} />
      </div>

      <Outlet />
      {/* <Footer /> */}
      <Footer2 />
      <FooterBottom />
    </div>
  );
};

// const SharedLayout = () => {
//   const handleSearch = (searchTerm) => {};
//   return (
//     <div>
//       <Navbar />
//       <RedirectSpecificUrl />
//       {/* Include the redirection logic */}
//       <div className="my-3">
//       <NavWithSearch onSearch={handleSearch} />
//       </div>
//       <Outlet />
//       {/* <Footer /> */}
//       <Footer2 />
//       <FooterBottom />
//     </div>
//   );
// };

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LowercaseUrlWrapper>
        <SharedLayout />
      </LowercaseUrlWrapper>
    ),
    children: [
      { index: true, element: <Layout /> },
      { path: "/:category/:brand", element: <Shop /> }, //1  category / brand
      { path: "/:category/:brand/:slug", element: <ProductDetails /> }, //2 product detail page
      { path: "/searchedresults", element: <SearchResult /> },
      { path: "/searchresult", element: <SearchResult /> },
      { path: "/profile", element: <Profile /> },
      { path: "/favourite", element: <Wishlist /> },
      { path: "/FilterKey", element: <FilterKey /> },
      { path: "/Coming", element: <Coming /> },
      { path: "/News", element: <NewsPage /> },
      { path: "/:category", element: <CategoryPage /> }, //3 brand ctegory
      { path: "/comparison", element: <NewComparePage /> },
      { path: "/comparetable", element: <ComparisonPage /> },
      { path: "/News/:slug", element: <NewsDetails /> },
      { path: "/mobilefinder", element: <MobileFinder /> },
      { path: "/blog", element: <Blog /> },
      { path: "/blog/:title", element: <BlogTest /> },
      { path: "/:category/:brand/TsPage", element: <TSPage /> },
      { path: "/trending", element: <Trending /> },
      { path: "/trending/:category", element: <TrendingDetails /> },
      { path: "/About", element: <About /> },
      { path: "/brand/:brand", element: <BrandPage /> },
      { path: "/ViewPopularGadgets", element: <ViewPopularGadgets /> },
      { path: "/ViewPopularPhones", element: <ViewPopularPhones /> },
    ],
  },
  {
    element: <PrivateRoute />,
    children: [
      { path: "/admin", element: <Dashboard /> },
      { path: "/admin/category", element: <Category /> },
      { path: "/admin/:category", element: <Brand /> },
      { path: "/admin/:category/:brand", element: <ProductList /> },
      {
        path: "/admin/:category/:brand/addproduct",
        element: <AdminAddProduct />,
      },
      { path: "/admin/update-product/:_id", element: <ProductUpdate /> },
      { path: "/admin/modified-product/:_id", element: <ProductModified /> },
      { path: "/admin/news", element: <AdminNews /> },
      { path: "/admin/news/update-news/:_id", element: <AdminNewsUpdate /> },
      { path: "/admin/brand/product", element: <Product /> },
      { path: "/admin/user", element: <UserList /> },
      { path: "/admin/newsletter", element: <Newsletter /> },
      { path: "/admin/profile", element: <AdminProfile /> },
      {
        path: "/admin/product-category",
        element: <AdminProductCategory />,
      },
      { path: "/admin/home-category", element: <AdminHC /> },
      {
        path: "/admin/home-category/brand",
        element: <AdminHCB />,
      },
      {
        path: "/admin/home-category/add",
        element: <AdminAddHC />,
      },
      {
        path: "/admin/home-category/update",
        element: <AdminUpdateHC />,
      },
    ],
  },
  { path: "/admin/auth", element: <AdminLogin /> },
  { path: "/login", element: <SignIn /> },
  { path: "/signup", element: <SignUp /> },
]);

const App = () => {
  // const location = useLocation();

  // console.log("amit", location);

  //   useEffect(() => {
  //     // Add Google Tag script dynamically
  //     const script = document.createElement("script");
  //     script.async = true;
  //     script.src = "https://www.googletagmanager.com/gtag/js?id=G-LB50GBN471";
  //     document.head.appendChild(script);

  //     // Initialize gtag
  //     const scriptTag = document.createElement("script");
  //     scriptTag.innerHTML = `
  //       window.dataLayer = window.dataLayer || [];
  //       function gtag(){dataLayer.push(arguments);}
  //       gtag('js', new Date());
  //       gtag('config', 'G-LB50GBN471');
  //     `;
  //     document.head.appendChild(scriptTag);

  //     // Optionally clean up when component unmounts
  //     return () => {
  //       document.head.removeChild(script);
  //       document.head.removeChild(scriptTag);
  //     };
  //   }, []);

  //   useEffect(() => {
  //     // Track page views on route change
  //     if (window.gtag) {
  //       window.gtag("config", "G-LB50GBN471", {
  //         page_path: location.pathname,
  //       });
  //     }
  //   }, [location]);

  //  useEffect(() => {
  //    // Track page views on route change
  //    if (window.gtag) {
  //      window.gtag("config", "G-LB50GBN471", {
  //        page_path: location.pathname,
  //      });
  //    }
  //  }, [location]);

  return (
    // <div className="font-bodyFont">
    //   <GoogleTagManager />
    //   <TrackPageView />
    //   <RouterProvider router={router} />
    // </div>
    <div className="font-bodyFont ">
      <GoogleTagManager />
      <RouterProvider router={router}>
        <TrackPageView />
      </RouterProvider>
    </div>
  );
};

export default App;
