import React from "react";
import Slider from "react-slick";
import SampleNextArrow from "../NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../NewArrivals/SamplePrevArrow";
import { Link } from "react-router-dom";

const SlideContent = ({ children }) => {
  return <div className="slide-content">{children}</div>;
};

const Brands = () => {
  const brands = [
    {
      name: "Lenovo",
      image: "https://mainprojectimg.s3.amazonaws.com/Lenovo_dxvgyb.webp",
    },
    {
      name: "LG",
      image: "https://mainprojectimg.s3.amazonaws.com/Lg_duygz8.webp",
    },
    {
      name: "Apple",
      image: "https://mainprojectimg.s3.amazonaws.com/apple_xmf55t.webp",
    },
    {
      name: "Dell",
      image: "https://mainprojectimg.s3.amazonaws.com/dell_nagdmt.webp",
    },
    {
      name: "HP",
      image: "https://mainprojectimg.s3.amazonaws.com/hp_co1yuv.webp",
    },
    {
      name: "Jbl",
      image: "https://mainprojectimg.s3.amazonaws.com/jbl_prjtcv.webp",
    },
    {
      name: "Mi",
      image: "https://mainprojectimg.s3.amazonaws.com/mi_dtxgef.webp",
    },
    {
      name: "Panasonic",
      image: "https://mainprojectimg.s3.amazonaws.com/panasonic_g5ubsn.webp",
    },
    {
      name: "Philips",
      image: "https://mainprojectimg.s3.amazonaws.com/philips_lv4l0t.webp",
    },
    {
      name: "Samsung",
      image: "https://mainprojectimg.s3.amazonaws.com/samsung_tmmzob.webp",
    },
    {
      name: "OPPO",
      image:
        "https://res.cloudinary.com/drlizzily/image/upload/v1702964175/g5ur5s8y55b94sxsu2d8.png",
    },
    {
      name: "Sony",
      image: "https://mainprojectimg.s3.amazonaws.com/sony_fa0aon.webp",
    },
    {
      name: "Realme",
      image: "https://mainprojectimg.s3.amazonaws.com/realme_m8q5py.webp",
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    dots: false,
  };

  return (
    <div className="custom-slider p-5">
      
      <h3 className="Heading-text">Top Brands </h3>
      <Slider {...settings}>
        {brands.map((brand, index) => (
          <SlideContent key={index}>
            <Link to={`/brand/${brand.name}`}>
              <img src={brand.image} alt={brand.name} />
              <p className="hover:no-underline text-[#0A6075] hover:text-[#064c5e] ">{brand.name}</p>
            </Link>
          </SlideContent>
        ))}
      </Slider>
    </div>
  );
};

export default Brands;
