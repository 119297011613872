import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCompare } from "../store/compareActions";
import { useNavigate } from "react-router-dom";

const ComparisonPage = () => {
  const dispatch = useDispatch();
  const compareItems = useSelector((state) => state.compare.compareItems);
  const [isAddProductPopupOpen, setAddProductPopupOpen] = useState(false);
  const nevigate = useNavigate()

  // Logic to open ComparePopup if no items are in the comparison table
  useEffect(() => {
    if (compareItems.length === 0) {
      setAddProductPopupOpen(true);
    } else {
      setAddProductPopupOpen(false);
    }
  }, [compareItems.length]); // Re-run this effect whenever compareItems changes

  // Extracting all properties from compared items, excluding specific keys
  const allProperties = Array.from(
    new Set(
      compareItems
        .flatMap((item) => Object.keys(item))
        .filter(
          (key) =>
            key !== "_id" &&
            key !== "img" &&
            key !== "manufacturer" &&
            key !== "country_of_origin" &&
            key !== "brand" &&
            key !== "category" &&
            key !== "pros" &&
            key !== "cons" &&
            key !== "updatedAt" &&
            key !== "price" &&
            key !== "comments"
        )
    )
  );

  function formatKeyCapitalkey(key) {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const handleRemoveItem = (item) => {
    dispatch(removeFromCompare(item));
  };

  const renderPropertyValue = (value) => {
    if (value === undefined || value === null || value === "") {
      return "—";
    } else if (typeof value === "object") {
      // Handle objects differently
      // This is a basic example that converts the object to a string
      // You might want to handle this more gracefully depending on your data structure
      return JSON.stringify(value);
    }
    return value.toString();
  };
  
  const handler = ()=>{
    nevigate("/comparison")
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="p-5">
        {/* {isAddProductPopupOpen && <ComparePopup />} */}

        {compareItems.length > 0 ? (
          <table className="table table-bordered p-4 responsive-table ">
            <thead
              className="fixed-header"
              style={{ padding: "10px", fontSize: "1.2rem" }}
            >
              <tr>
                <th></th>
                {compareItems.map((item, index) => (
                  <th key={index}>
                    <button
                      onClick={() => handleRemoveItem(item)}
                      className="btn btn-link btn-sm mt-2"
                      style={{ padding: 0, position: "relative", color: "red" }}
                    >
                      &#10006; {/* X symbol */}
                    </button>
                    <img
                      src={item.img}
                      alt={item.model_name}
                      style={{
                        maxWidth: "100px",
                        height: "100px",
                        marginTop: "5px",
                        objectFit: "contain",
                      }}
                      className="image-table"
                    />
                    <br />
                    <br />
                    {/* <div className="hello "> {item.model_name}</div> */}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allProperties.map((property) => (
                <tr key={property}>
                  <td
                    style={{ color: "#1599b9" }}
                    className="hello text-center"
                  >
                    {formatKeyCapitalkey(property)}
                  </td>
                  {compareItems.map((item, index) => (
                    <td
                      key={index}
                      style={{ padding: "10px", fontSize: "1.2rem" }}
                      className="hello"
                    >
                      {renderPropertyValue(item[property])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          // Optional: Render some placeholder or message when there are no items to compare
          <div className="my-5 w-full text-center">
            
           <button
                className="bg-[#0A6075] text-white  px-3 py-2 rounded-lg hover:bg-[#064c5e] hover:no-underline"
                onClick={handler}
                style={{
                  cursor: "pointer",
                }}
              >
                Compare Now
              </button>
          </div>
          // <div>Please add items to compare.</div>
        )}
      </div>
    </>
  );
};

export default ComparisonPage;
