import React, { useState, useEffect, useContext } from "react";
import CategorySelector from "./CategorySelector";
import ProductBox from "./ProductBox";
import SearchPopup from "./SearchPopup";
import ComparisonTable from "./ComparisonTable";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const NewComparePage = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [products, setProducts] = useState([null, null, null]);
  const [data, setData] = useState(null);
  const { category: rawCategory, brand, slug } = useParams();

  useEffect(() => {
    if (selectedCategory.length > 0 && selectedProducts.length > 0) {
      window.confirm("Do you want to Clear saved Products...");
      setSelectedProducts([]);
    }
  }, [selectedCategory]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleOpenPopup = (category) => {
    setSelectedCategory(category);
    setIsPopupOpen(true);
  };

  const handleProductSelect = (product) => {
    const isItemInCompare = selectedProducts.some(
      (item) => item._id === product._id
    );

    if (!isItemInCompare) {
      setSelectedProducts((prev) => [...prev, product]);
      toast.success("Item added to compare!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info("Item is already in the comparison list.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setIsPopupOpen(false);
  };

  const handleCompareNow = () => {
    setShowTable(true);
  };

  const handleDelete = (index) => {
    const updatedProducts = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(updatedProducts); // Update state after removing the product
    toast.info("Product removed from comparison list.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full mx-auto p-4">
      <CategorySelector onCategorySelect={handleCategorySelect} />
      <div className="md:flex grid grid-cols-2 gap-1 flex-wrap justify-evenly p-2 items-center w-[90%] m-auto">
        {[...Array(4)].map((_, index) => (
          <ProductBox
            onDelete={() => handleDelete(index)}
            key={index}
            product={selectedProducts[index]}
            onOpenPopup={() => handleOpenPopup(selectedCategory)}
          />
        ))}
      </div>
      <div className="w-full flex justify-center my-5">
        <button
          className="bg-[#0A6075] items-center-center m-auto text-white px-3 py-2 rounded-lg hover:bg-[#064c5e] hover:no-underline"
          onClick={handleCompareNow}
          disabled={selectedProducts.length < 2}
        >
          Compare Now
        </button>
      </div>
      {isPopupOpen && (
        <SearchPopup
          setSlug={setData}
          selectedOptionCategory={selectedCategory}
          onSelectProduct={handleProductSelect}
          onClose={() => setIsPopupOpen(false)}
        />
      )}

      {showTable && (
        <ComparisonTable
          products={selectedProducts}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default NewComparePage;
