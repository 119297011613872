import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Haeding";
import Product from "../Products/Product";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { newArrival_Api } from "../../../Service/homeService";

const NewArrivals = () => {
  const [newdata, Setnewdata] = useState([]);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const fetchData = async () => {
    try {
      const response = await newArrival_Api();
      // Sort the response based on release_date in descending order
      const sortedData = response.products.sort(
        (a, b) => new Date(b.release_date) - new Date(a.release_date)
      );
      Setnewdata(sortedData);
      // console.log("sorted data new arrival",sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getFirstFiveWords = (str) => {
    if (typeof str === "string" && str.trim().length > 0) {
      const words = str.split(" ");
      return words.slice(0, 5).join(" ");
    } else {
      return null; // Return null if there's no valid model name
    }
  };

  return (
    <div className="w-full pb-16 mt-5 mb-5">
      <Heading heading="New Arrivals" />
      <Slider {...settings}>
        {newdata
          ?.filter((item) => getFirstFiveWords(item.model_name) !== null)
          .map((item, index) => {
            // Optional: Provide fallbacks or handle undefined for category and brand
            const productName = getFirstFiveWords(item.model_name);
            const category = item.category ?? "Uncategorized"; // Fallback if undefined
            const brand = item.brand ?? "No Brand"; // Fallback if undefined

            // Proceed to render only if productName exists
            if (productName) {
              return (
                <div key={index} className="px-2">
                  <Product
                    _id={item._id}
                    img={item.img}
                    productName={productName}
                    slug={item.slug}
                    price="44.00"
                    category={category}
                    brand={brand}
                    badge={true}
                    des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
                  />
                </div>
              );
            }
            return null; // Don't render anything if conditions are not met
          })}
      </Slider>
    </div>
  );
};

export default NewArrivals;
