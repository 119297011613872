import React from "react";

const ProductBox = ({ product, onOpenPopup, onDelete }) => {
  return (
    < >
      {product ? (
        <div className="relative min-h-40 md:max-w-[20%] border-1 overflow-hidden border-dashed rounded border-[#0a6075] p-4 flex items-center justify-center">
          <div className="flex flex-col items-center">
            <button
              className="absolute top-1 right-1 text-red-500 font-bold text-lg rounded-full p-1 hover:text-red-700"
              onClick={onDelete}
            >
              &times;
            </button>
            <img src={product.img} alt={product.model_name} className="h-20" />
            <p className="line-clamp-1">{product.model_name}</p>
          </div>
        </div>
      ) : (
        <div
          onClick={onOpenPopup}
          className="relative h-36 md:w-[20%] bg-white border-1 border-dashed rounded border-[#0a6075] p-4 flex items-center justify-center"
        >
          <span className="text-md">Add +</span>
        </div>
      )}
    </>
  );
};

export default ProductBox;
