import React, { useState, useEffect, useContext } from "react";
import { searchForComparison } from "../../../Service/searchService";
import { getSingleProductData } from "../../../Service/Api";
import { useParams } from "react-router-dom";


const SearchPopup = ({ onSelectProduct, onClose, selectedOptionCategory,setSlug }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [loading, setLoading] = useState(true); // New loading state
  const [error, setError] = useState(null); // New error state
  const { category: rawCategory, brand, slug } = useParams();
  const category = rawCategory || "";

  function convertToHyphenatedString(str) {
    if (typeof str !== "string") return ""; // Check if input is a string
    return str.trim().replace(/\s+/g, "-").toLowerCase();
  }

  const compareItems = convertToHyphenatedString(selectedOptionCategory);
  console.log("category>>>>>", compareItems);

  // Determine the category to filter by, based on the first item in the comparison list
  const selectedCategory = compareItems.length > 0 ? compareItems : null;

  useEffect(() => {
    const fetchResults = async () => {
      if (searchQuery.trim() !== "") {
        try {
          // Explicitly pass 'null' or a specific parameter to indicate all categories should be fetched
          const categoryFilter = selectedCategory ? selectedCategory : null;
          const response = await searchForComparison(
            searchQuery,
            categoryFilter
          );
          setSearchResults(response.results);
        } catch (error) {
          console.error("Failed to fetch search results:", error);
        }
      } else {
        // If no search query, potentially clear results or handle differently
        setSearchResults([]);
      }
    };
    fetchResults();
  }, [searchQuery, selectedCategory]); // Ensure dependencies are correctly listed

  // Fetch product data from API
  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const responseData = await getSingleProductData(category, brand, slug);
        if (responseData && responseData.status === "success") {
          setData(responseData.data);
          setSlug(responseData.data)
        } else {
          throw new Error(
            responseData.message || "Error fetching product data"
          );
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchDataFromApi();
  }, [category, brand, slug]);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedItemIndex((prevIndex) =>
        prevIndex < searchResults.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedItemIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (event.key === "Enter" && selectedItemIndex !== -1) {
      onSelectProduct(searchResults[selectedItemIndex]);
    }
  };

  return (
    <div className="fixed inset-0 top-20 p-1 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-md  max-w-[40rem]">
        <div className="flex justify-between bg-[#0a6075] p-2 rounded-md items-center">
          <h2 className="text-lg text-white font-bold">Search and Add Product</h2>
          <button className="text-white font-bold p-2 text-lg" onClick={onClose}>&times;</button>
        </div>
        <input
          type="text"
          placeholder="Search..."
          className="w-full p-2 border mt-4"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <div
          className=" mt-2 p-1 overflow-auto max-h-[400px] overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
        >
          {searchQuery !== "" && (
            <div
              className="mt-1 max-h-[55vh] cursor-pointer"
              // ref={containerRef}
            >
              {searchResults.map((result, index) => (
                <div
                  className={`border p-3 ${
                    index === selectedItemIndex ? "bg-light" : ""
                  }`}
                  key={result._id}
                  onClick={() => onSelectProduct(result)}
                >
                  {result.model_name} - {result.category || "All Categories"} -{" "}
                  {result.brand}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchPopup;
