import React from "react";

const ComparisonTable = ({ products,handleDelete }) => {
  // Extracting all properties from compared items, excluding specific keys
  const allProperties = Array.from(
    new Set(
      products
        .flatMap((item) => Object.keys(item))
        .filter(
          (key) =>
            key !== "_id" &&
            key !== "img" &&
            key !== "manufacturer" &&
            key !== "country_of_origin" &&
            key !== "brand" &&
            key !== "category" &&
            key !== "pros" &&
            key !== "cons" &&
            key !== "updatedAt" &&
            key !== "price" &&
            key !== "comments"
        )
    )
  );

  function formatKeyCapitalkey(key) {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const renderPropertyValue = (value) => {
    if (value === undefined || value === null || value === "") {
      return "—";
    } else if (typeof value === "object") {
      // Handle objects differently
      // This is a basic example that converts the object to a string
      // You might want to handle this more gracefully depending on your data structure
      return JSON.stringify(value);
    }
    return value.toString();
  };

  return (
    <div className="p-5">
      <table className="table table-bordered p-4 responsive-table ">
        <thead className="" style={{ padding: "10px", fontSize: "1.2rem" }}>
          <tr>
            <th></th>
            {products.map((item, index) => (
              <th key={index}>
                <button
                  onClick={() => handleDelete(index)}
                  className="btn btn-link btn-sm mt-2"
                  style={{ padding: 0, position: "relative", color: "red" }}
                >
                  &#10006; {/* X symbol */}
                </button>
                <img
                  src={item.img}
                  alt={item.model_name}
                  style={{
                    maxWidth: "100px",
                    height: "100px",
                    marginTop: "5px",
                    objectFit: "contain",
                  }}
                  className=""
                />
                <br />
                <br />
                {/* <div className="hello "> {item.model_name}</div> */}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allProperties.map((property) => (
            <tr key={property}>
              <td style={{ color: "#1599b9" }} className="hello text-center">
                {formatKeyCapitalkey(property)}
              </td>
              {products.map((item, index) => (
                <td
                  key={index}
                  style={{ padding: "10px", fontSize: "1.2rem" }}
                  className="hello"
                >
                  {renderPropertyValue(item[property])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default ComparisonTable;
