import React, { useState, useEffect } from "react";
import { FaSearch, FaUser, FaCaretDown } from "react-icons/fa";
import Flex from "../../../Components/DesignLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { searchSuggestion } from "../../../Service/featureService";

const HeaderBottem = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [result, SetResult] = useState([]);
  const [showUser, setShowUser] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(".search-box")) {
        // Only clear the search results, but not the input field
        SetResult([]);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideUserDropdown = (e) => {
      if (!e.target.closest(".user-dropdown")) {
        setShowUser(false);
      }
    };

    if (showUser) {
      document.addEventListener("click", handleClickOutsideUserDropdown);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsideUserDropdown);
    };
  }, [showUser]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchData = async () => {
    if (searchQuery.trim() !== "") {
      let response = await searchSuggestion(searchQuery);
      SetResult(response.results);
    }
  };

  const navigateto = (item) => {
    navigate(`/searchresult?query=${item}`);
    setSearchQuery("");
  };

  useEffect(() => {
    fetchData();
  }, [searchQuery]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchQuery.trim() !== "") {
      // Replace spaces with hyphens
      const formattedQuery = searchQuery.trim().replace(/\s+/g, "-");

      // Limit the query length to 20 characters
      const truncatedQuery =
        formattedQuery.length > 20
          ? formattedQuery.substring(0, 20)
          : formattedQuery;

      // Navigate to the search results page with the formatted query
      navigate(`/searchresult?query=${truncatedQuery}`);

      // Clear the search query and the result after navigation
      setSearchQuery("");
      SetResult([]); // Clear the search result list
    }
  };

  return (
    <div className="mx-auto max-w-7xl p-2 mt-24">
      <div className="relative isolate z-50 text-center p-1 ">
        <p className="mx-auto max-w-2xl font-bold  tracking-tight text-gray-900 sm:text-4xl ">
          Every Gadget Tells a Story
        </p>
        <p className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Find Your's Ideal Gadget
        </p>
        <Flex className="flex flex-col items-center justify-center lg:flex-row  lg:items-center  w-full px-1 lg:pb-0 h-full lg:h-24">
          <div className="ser mt-4 bg-[#D5F3FB] shadow-lg relative w-full lg:w-[600px] md:w-[500px] h-[50px] text-base text-primeColor  flex items-center gap-2 justify-between px-6 rounded-xl search-box-wrapper">
            <input
              className=" p-2 w-full rounded-md flex-1 outline-none bg-[#D5F3FB]"
              type="text"
              onChange={handleSearch}
              onKeyDown={handleKeyPress}
              value={searchQuery}
              placeholder="What are you looking for?"
            />
            <FaSearch className="w-5 h-5" />
            {result.length > 0 && (
              <div className="search-suggestion-dropdown">
                {result.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => navigateto(item.suggestion)}
                    className="search-suggestion-item text-left z-9999"
                  >
                    {item.suggestion
                      .split("-")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Flex>
      </div>
      <ToastContainer />
    </div>
  );
};

export default HeaderBottem;