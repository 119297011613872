// import React from "react";
// import { Link } from "react-router-dom";

// const HomeGrid = () => {
//   return (
//     <section className="w-[100%] -z-10">
//       <div className="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-7 gap-4 h-full w-[100%] ">
//           {/* First Large Image - Wines */}
//           <div className="-z-20 col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col ">
//             <a
//               href="#"
//               className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow"
//             >
//               <img
//                 src="https://i.postimg.cc/bv28j4Xv/8.jpg"
//                 alt="Wines"
//                 className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
//               />
//               <div className=" -z-10 inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
//               {/* <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
//                 Laptop
//               </h3> */}
//             </a>
//           </div>

//           {/* Second Large Image - Gin */}
//           <div className="-z-20 col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
//             <a
//               href="#"
//               className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4"
//             >
//               <img
//                 src="https://i.postimg.cc/wMKTRgpZ/4.jpg"
//                 alt="Gin"
//                 className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
//               />
//               <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
//               {/* <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
//                 mobile
//               </h3> */}
//             </a>

//             {/* First small image - Whiskey */}
//             <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
//               <a
//                 href="#"
//                 className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
//               >
//                 <img
//                   src="https://i.postimg.cc/m2XtZxn3/6.png"
//                   alt="Whiskey"
//                   className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
//                 />
//                 <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
//                 {/* <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
//                   Whiskey
//                 </h3> */}
//               </a>

//               {/* Second small image - Vodka */}
//               <a
//                 href="#"
//                 className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
//               >
//                 <img
//                   src="https://i.postimg.cc/Dy93PB4k/3.jpg"
//                   alt="Vodka"
//                   className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
//                 />
//                 <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
//                 {/* <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
//                   Vodka
//                 </h3> */}
//               </a>
//             </div>
//           </div>

//           {/* Third Large Image - Brandy */}
//           <div className="-z-20 col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
//             <a
//               href="#"
//               className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow"
//             >
//               <img
//                 src="https://i.postimg.cc/g0SW8dv4/11.jpg"
//                 alt="Brandy"
//                 className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
//               />
//               <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
//               {/* <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
//                 Brandy
//               </h3> */}
//             </a>
//           </div>

//           <div className="-z-20 col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
//             <Link
//               to=""
//               className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4"
//             >
//               <img
//                 src="https://i.postimg.cc/KjhsgssJ/2.jpg"
//                 alt="Gin"
//                 className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
//               />
//               <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
//               {/* <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
//                 Gin
//               </h3> */}
//             </Link>

//             {/* First small image - Whiskey */}
//             <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-1">
//               <a
//                 href="#"
//                 className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
//               >
//                 <img
//                   src="https://i.postimg.cc/tTpxZ3NF/9.jpg"
//                   alt="Whiskey"
//                   className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
//                 />
//                 <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
//                 {/* <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
//                   Whiskey
//                 </h3> */}
//               </a>

//               {/* Second small image - Vodka */}

//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default HomeGrid;

import React from "react";
import { Link } from "react-router-dom";

const HomeGrid = () => {
  return (
    <section className="w-[100%] -z-10">
      <div className="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-7 gap-4 h-full w-[100%]">
          {/* First Large Image - Wines */}
          <div className=" col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
            <Link
              to="/laptops"
              className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow"
            >
              <img
                src="https://res.cloudinary.com/dkjztezu0/image/upload/v1729486567/8_jgljky.jpg"
                alt=""
                className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
              />
              <div className=" inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
            </Link>
          </div>

          {/* Second Large Image - Gin */}
          <div className=" col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
            <Link
              to="/mobiles"
              className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4"
            >
              <img
                src="https://res.cloudinary.com/dkjztezu0/image/upload/v1729486566/4_g8cpxp.jpg"
                alt=""
                className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
            </Link>

            {/* First small image - Whiskey */}
            <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
              <Link
                to="/smart-glass"
                className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
              >
                <img
                  src="https://res.cloudinary.com/dkjztezu0/image/upload/v1729486567/6_xfpczh.png"
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
              </Link>

              {/* Second small image - Vodka */}
              <Link
                to="/printer"
                className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
              >
                <img
                  src="https://res.cloudinary.com/dkjztezu0/image/upload/v1729486566/3_aobj8x.jpg"
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
              </Link>
            </div>
          </div>

          {/* Third Large Image - Brandy */}
          <div className=" col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
            <Link
              to="/earphones"
              className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow"
            >
              <img
                src="https://res.cloudinary.com/dkjztezu0/image/upload/v1729486567/11_bymh7h.jpg"
                alt=""
                className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
            </Link>
          </div>

          {/* Fourth Large Image */}
          <div className=" col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
            <Link
              to="/router"
              className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4"
            >
              <img
                src="https://res.cloudinary.com/dkjztezu0/image/upload/v1729486566/2_seoqwb.jpg"
                alt=""
                className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
            </Link>

            {/* Small Image - Whiskey */}
            <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-1">
              <Link
                to="/speakers"
                className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
              >
                <img
                  src="https://res.cloudinary.com/dkjztezu0/image/upload/v1729486567/9_zyszpx.jpg"
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeGrid;