import { useEffect, useState } from "react";
import { getSingleProductData } from "../../Service/Api";
import { useParams } from "react-router-dom";

const DescriptionTable = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { category: rawCategory, brand, slug } = useParams();
  const category = rawCategory || "";

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const responseData = await getSingleProductData(category, brand, slug);
        if (responseData && responseData.status === "success") {
          setData(responseData.data);
        } else {
          throw new Error(
            responseData.message || "Error fetching product data"
          );
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchDataFromApi();
  }, [category, brand, slug]);

  const renderTableRows = () => {
    if (!data) return []; // Return an empty array if data is null

    return Object.keys(data)
      .filter(
        (property) =>
          ![
            "_id",
            "slug",
            "img",
            "manufacturer",
            "country_of_origin",
            "brand",
            "category",
            "pros",
            "cons",
            "updatedAt",
            "price",
            "3",
            "createdAt",
            "readMore",
            "metaTitle",
            "metaKeywords",
            "metaDescription",
          ].includes(property)
      )
      .map((property, index) => {
        const value = data[property];
        if (
          !value ||
          (typeof value === "object" && Object.keys(value).length === 0)
        ) {
          return null;
        }

        const rowStyle = index % 2 === 0 ? "bg-white" : "bg-[#f7fafc]";

        return (
          <tr key={property} className={rowStyle}>
            <td className="p-3 border font-semibold text-[#0A6075] border-gray-300 break-words whitespace-normal text-sm min-w-[120px]">
              {property
                .replace(/_/g, " ")
                .replace(/\b\w/g, (match) => match.toUpperCase())}
            </td>
            <td className="p-3 border border-gray-300 break-words whitespace-normal text-black text-sm min-w-[180px]">
              {typeof value === "object" ? (
                <div>
                  {Object.entries(value).map(([key, val]) => (
                    <p key={key} className="mb-1">
                      <strong>
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (match) => match.toUpperCase())}
                        :
                      </strong>{" "}
                      {typeof val === "object"
                        ? JSON.stringify(val)
                        : val}
                    </p>
                  ))}
                </div>
              ) : (
                <div>{value}</div>
              )}
            </td>
          </tr>
        );
      });
  };

  const rows = renderTableRows();
  const half = Math.ceil(rows.length / 2); // Divide rows equally

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      {data && (
        <div className="w-full flex flex-col my-3 items-center justify-center">
          {/* Title Section */}
          <div className="w-10/12 flex items-start justify-start">
            <h1 className="sm:text-4xl font-bold text-2xl p-2 m-2">
              {data.model_name} <b className="text-[#0A6075]">Specifications</b>
            </h1>
          </div>

          {/* Responsive Table Grid */}
          <div className="w-10/12 grid grid-cols-1  lg:grid-cols-2 gap-8">
            {/* First Table */}
            <div className="overflow-x-auto h-fit rounded-lg shadow-md">
              <table className="min-w-full border-collapse">
                <thead className="bg-[#e1e8ec]">
                  <tr>
                    <th className="text-white text-lg bg-[#0A6075] break-words whitespace-normal font-bold sm:text-xl md:text-2xl text-left px-4 py-3 border border-gray-300">
                      TITLE
                    </th>
                    <th className="text-white text-lg bg-[#0A6075] break-words whitespace-normal font-bold sm:text-xl md:text-2xl text-left px-4 py-3 border border-gray-300">
                      DESCRIPTION
                    </th>
                  </tr>
                </thead>
                <tbody>{rows.slice(0, half)}</tbody> {/* First half */}
              </table>
            </div>

            {/* Second Table (Hidden on Small Screens) */}
            <div className="overflow-x-auto rounded-lg h-fit shadow-md lg:block hidden">
              <table className="min-w-full border-collapse">
                <thead className="bg-[#e1e8ec]">
                  <tr>
                    <th className="text-white text-lg bg-[#0A6075] break-words whitespace-normal font-bold sm:text-xl md:text-2xl text-left px-4 py-3 border border-gray-300">
                      TITLE
                    </th>
                    <th className="text-white text-lg bg-[#0A6075] break-words whitespace-normal font-bold sm:text-xl md:text-2xl text-left px-4 py-3 border border-gray-300">
                      DESCRIPTION
                    </th>
                  </tr>
                </thead>
                <tbody>{rows.slice(half)}</tbody> {/* Second half */}
              </table>
            </div>
          </div>

          {/* Single Merged Table for Small Screens */}
          <div className="w-10/12 block lg:hidden h-fit overflow-x-auto rounded-lg shadow-md mt-8">
            <table className="min-w-full border-collapse">
              <thead className="bg-[#e1e8ec]">
                <tr>
                  <th className="text-white text-lg bg-[#0A6075] break-words whitespace-normal font-bold sm:text-xl md:text-2xl text-left px-4 py-3 border border-gray-300">
                    TITLE
                  </th>
                  <th className="text-white text-lg bg-[#0A6075] break-words whitespace-normal font-bold sm:text-xl md:text-2xl text-left px-4 py-3 border border-gray-300">
                    DESCRIPTION
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody> {/* All rows merged */}
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default DescriptionTable;
