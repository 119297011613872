// with react quil

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import ReactDOM from 'react-dom';
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import { adminGetSingleProduct, adminUpdateProduct } from "../../Service/admin-service";

// export default function ProductUpdate() {
//   const [formData, setFormData] = useState({});
//   const [modifiedFields, setModifiedFields] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [showModal, setShowModal] = useState(false); // Modal state
//   const [readMoreContent, setReadMoreContent] = useState("");
//   const { _id } = useParams();

//   // Fetch the product data on component mount
//   useEffect(() => {
//     async function fetchProduct() {
//       try {
//         const response = await adminGetSingleProduct(_id);
//         const data = response.data;
//         setFormData(data);
//         setReadMoreContent(data.readMore || ""); // Initialize readMore content
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching product:", error);
//         setLoading(false);
//       }
//     }

//     fetchProduct();
//   }, [_id]);

//   // Handle changes in form fields
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setModifiedFields({ ...modifiedFields, [name]: value });
//   };

//   // Handle React-Quill change for 'readMore' content
//   const handleQuillChange = (content) => {
//     setReadMoreContent(content);
//     setModifiedFields({ ...modifiedFields, readMore: content });
//   };

//   // Handle form submission (product update)
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await adminUpdateProduct(_id, modifiedFields);
//       alert(response.message);
//     } catch (error) {
//       console.log(error);
//       console.error("Error updating product:", error);
//     }
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   // Function to open modal
//   const openModal = () => setShowModal(true);

//   // Function to close modal
//   const closeModal = () => setShowModal(false);

//   // Full toolbar configuration for React-Quill
//   const fullToolbarOptions = [
//     [{ header: "1" }, { header: "2" }, { font: [] }],
//     [{ size: [] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [{ list: "ordered" }, { list: "bullet" }],
//     ["link", "image", "video"],
//     ["clean"], // remove formatting
//     [{ color: [] }, { background: [] }],
//     [{ align: [] }],
//     ["code-block"],
//   ];

//   return (
//     <div className="card">
//       <div className="card-body">
//         <h5 className="card-title">Update Product</h5>
//         <form onSubmit={handleSubmit}>
//           <table className="table">
//             <tbody>
//               {Object.entries(formData).map(([key, value]) => (
//                 <tr key={key}>
//                   <td>
//                     <label htmlFor={key}>{key.toUpperCase()}</label>
//                   </td>
//                   <td colSpan="2">
//                     {key === "readMore" ? (
//                       <>
//                         <button
//                           type="button"
//                           className="px-4 py-2 bg-blue-500 text-white rounded-md"
//                           onClick={openModal}
//                         >
//                           Edit ReadMore
//                         </button>
//                       </>
//                     ) : (
//                       <input
//                         type="text"
//                         className="form-control"
//                         id={key}
//                         name={key}
//                         value={modifiedFields[key] || value}
//                         onChange={handleChange}
//                       />
//                     )}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <button type="submit" className="btn btn-primary">
//             Update
//           </button>
//         </form>
//       </div>

//       {/* Tailwind Modal */}
//       {showModal && ReactDOM.createPortal(
//         <div className="fixed z-50 inset-0 overflow-y-auto">
//           <div className="flex items-center justify-center min-h-screen px-4">
//             <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

//             <div className="bg-white rounded-lg shadow-xl overflow-hidden max-w-2xl w-full z-50">
//               <div className="px-4 py-5 sm:p-6">
//                 <h3 className="text-lg leading-6 font-medium text-gray-900">Edit ReadMore</h3>
//                 <div className="mt-5">
//                   <ReactQuill
//                     theme="snow"
//                     value={readMoreContent}
//                     onChange={handleQuillChange}
//                     modules={{
//                       toolbar: fullToolbarOptions, // Apply the full toolbar
//                     }}
//                   />
//                 </div>
//               </div>
//               <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
//                 <button
//                   type="button"
//                   className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
//                   onClick={closeModal}
//                 >
//                   Save
//                 </button>
//                 <button
//                   type="button"
//                   className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
//                   onClick={closeModal}
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>,
//         document.body // This ensures the modal is rendered at the body level
//       )}
//     </div>
//   );
// }



import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  adminGetSingleProduct,
  adminUpdateProduct,
} from "../../Service/admin-service";

export default function ProductUpdate() {
  const [formData, setFormData] = useState({});
  const [modifiedFields, setModifiedFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [readMoreContent, setReadMoreContent] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState(""); // Store image URL
  const { _id } = useParams();

  // Fetch the product data on component mount
  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await adminGetSingleProduct(_id);
        const data = response.data;
        setFormData(data);
        setReadMoreContent(data.readMore || "");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setLoading(false);
      }
    }

    fetchProduct();
  }, [_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModifiedFields({ ...modifiedFields, [name]: value });
  };

  const handleEditorChange = (content) => {
    setReadMoreContent(content);
    setModifiedFields({ ...modifiedFields, readMore: content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await adminUpdateProduct(_id, modifiedFields);
      alert(response.message);
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "read_more"); // Use your Cloudinary preset

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dkjztezu0/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Uploaded image data:", data);
      setUploadedImageUrl(data.secure_url); // Save the image URL
    } catch (error) {
      console.error("Image upload failed", error);
      alert("Image upload failed. Please try again.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Update Product</h5>
        <form onSubmit={handleSubmit}>
          <table className="table">
            <tbody>
              {Object.entries(formData).map(([key, value]) => (
                <tr key={key}>
                  <td>
                    <label htmlFor={key}>{key.toUpperCase()}</label>
                  </td>
                  <td colSpan="2">
                    {key === "readMore" ? (
                      <button
                        type="button"
                        className="px-4 py-2 bg-blue-500 text-white rounded-md"
                        onClick={openModal}
                      >
                        Edit ReadMore
                      </button>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id={key}
                        name={key}
                        value={modifiedFields[key] || value}
                        onChange={handleChange}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </form>
      </div>

      {showModal &&
        ReactDOM.createPortal(
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
              <div className="bg-white rounded-lg shadow-xl overflow-hidden max-w-6xl w-full z-50">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Edit ReadMore
                  </h3>
                  <div className="mt-5">
                    <Editor
                      // tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/js/tinymce.min.js`}
                      tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/js/tinymce/tinymce.min.js`}
                      value={readMoreContent}
                      onEditorChange={handleEditorChange}
                      init={{
                        license_key: "gpl",
                        height: 400,
                        menubar: true,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | bold italic | alignleft aligncenter alignright | " +
                          "bullist numlist | image | link",
                      }}
                    />
                  </div>
                  <div className="mt-5">
                    <h4 className="text-lg">Upload Image</h4>
                    <input type="file" onChange={handleImageUpload} />
                    {uploadedImageUrl && (
                      <div className="mt-2">
                        <p>Image URL:</p>
                        <input
                          type="text"
                          className="w-full p-2 border"
                          value={uploadedImageUrl}
                          readOnly
                        />
                        <p className="mt-1 text-sm text-gray-600">
                          Copy the above URL and paste it into the TinyMCE
                          editor.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-white"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
}
