import React, { useState } from "react";
import ComparisonSearch from "../ComparisonSearch";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCompare, addToCompare } from "../store/compareActions";
import { useNavigate } from "react-router-dom";

const ComparisonModal = (prop) => {
  const { compareItems } = useSelector(
    (state) => state.compare || { compareItems: [] }
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSearchPopupOpen, setSearchPopupOpen] = useState(false);

  const handleRemoveFromCompare = (itemToRemove) => {
    dispatch(removeFromCompare(itemToRemove));
  };

  // const onAddToCompare = (item) => {
  //   dispatch(addToCompare(item));
  //   setSearchPopupOpen(false);
  // };

  const handleCompareNow = () => {
    if (compareItems.length >= 2) {
      navigate("/comparetable");
    } else {
      console.log("Need at least two items for comparison.");
    }
  };

  const handleOpenSearchPopup = () => setSearchPopupOpen(true);
  const handleCloseSearchPopup = () => setSearchPopupOpen(false);

  return (
    <>
      <h3 className="Heading-com mb-4">
        Confused? Compare Your Products Now..
      </h3>
      <div className="container text-center">
        <div className="col-12 row">
          <div className="col-lg-12 col-md-12 col-sm-6 ">
            <div className="product-cards ">
              {[...Array(4)].map((_, index) => {
                const item = compareItems[index];
                return (
                  <React.Fragment key={index}>
                    {item ? (
                      <div className="product-card">
                        <div
                          className="remove"
                          onClick={() => handleRemoveFromCompare(item)}
                        >
                          &times;
                        </div>
                        <img
                          src={item.img}
                          alt={item.model_name}
                          className="product-image"
                        />
                        <p className="product-details">{item.model_name}</p>
                      </div>
                    ) : (
                      <div
                        className="empty-item border card-body"
                        onClick={handleOpenSearchPopup}
                      >
                        +
                      </div>
                    )}
                    {/* Always render "vs" text except after the last item */}
                    {/* {index < 3 && (
                  <div
                    className="vs-text mx-2"
                    style={{ fontSize: "24px", fontWeight: "bold" }}
                  >
                    vs
                  </div>
                )} */}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-6 my-2">
              <button
                className="bg-[#0A6075] text-white  px-3 py-2 rounded-lg hover:bg-[#064c5e] hover:no-underline"
                onClick={handleCompareNow}
                disabled={compareItems.length < 2}
                style={{
                  opacity: compareItems.length < 2 ? 0.5 : 1,
                  cursor: compareItems.length < 2 ? "not-allowed" : "pointer",
                }}
              >
                Compare Now
              </button>
            </div>
          </div>
        </div>
      </div>
      {isSearchPopupOpen && (
        <ComparisonSearch
          // onAddToCompare={onAddToCompare}
          onClose={handleCloseSearchPopup}
        />
      )}
    </>
  );
};

export default ComparisonModal;
