import React, { useEffect, useState } from "react";
import { getSingleProductData } from "../../../Service/Api"; // Adjust the import path to where your API function is located

export default function Pros(props) {
  const [selectedItem, setSelectedItem] = useState({ pros: [], cons: [] });
  const [loading, setLoading] = useState(true); // State to manage loading state

  useEffect(() => {
    const fetchData = async () => {
      // Extracting props
      const { category, brand, slug } = props;

      try {
        const response = await getSingleProductData(category, brand, slug);
        if (response && response.status === "success") {
          setSelectedItem(response.data);
        } else {
          console.error(
            "API call was successful but did not return expected data."
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or error
      }
    };
    fetchData();
  }, [props]); // Now useEffect will run whenever props change

  return (
    <div className="w-full flex justify-center items-center">
      <div className="md:w-10/12 w-full mx-auto md:p-2">
        {/* Title */}
        <h3 className=" font-bold text-start text-4xl mb-6">
          Know <span className="text-[#0A6075]">Pros & Cons</span>
        </h3>

        {/* Wrapper */}
        <div className="flex flex-col md:flex-row justify-between gap-6">
          {/* Pros Section */}
          <div className="flex-1 rounded-md overflow-hidden">
            <div
              className="bg-[#68A624] text-white p-3 flex items-center"
              // style={'box-shadow:10px 10px green;'}
            >
              <i className="bi bi-check-circle-fill text-xl mr-2"></i>
              <h4 className="text-lg font-bold">Pros</h4>
            </div>
            <div className="bg-[#F9F9F9] border border-[#68A624] p-4">
              {loading ? (
                <p>Loading...</p>
              ) : selectedItem.pros && selectedItem.pros.length > 0 ? (
                selectedItem.pros.map((pro, index) => (
                  <div key={`pro-${index}`} className="flex items-start mb-3">
                    <i className="bi bi-check-circle-fill text-[#68A624] mr-1 mt-1 p-1"></i>
                    <p className="sm:text-lg text-xs p-1">{pro}</p>
                  </div>
                ))
              ) : (
                <p>No pros available</p>
              )}
            </div>
          </div>

          {/* Cons Section */}
          <div className="flex-1 rounded-md overflow-hidden">
            <div className="bg-red-600 text-white p-3 flex items-center">
              <i className="bi bi-x-circle-fill text-xl mr-2"></i>
              <h4 className="text-lg font-bold">Cons</h4>
            </div>
            <div className="bg-[#F9F9F9] border border-red-500 p-4">
              {loading ? (
                <p>Loading...</p>
              ) : selectedItem.cons && selectedItem.cons.length > 0 ? (
                selectedItem.cons.map((con, index) => (
                  <div key={`con-${index}`} className="flex items-start mb-3">
                    <i className="bi bi-x-circle-fill text-red-500 mt-1 p-1 mr-2"></i>
                    <p className="sm:text-lg text-xs p-1">{con}</p>
                  </div>
                ))
              ) : (
                <p>No cons available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
