import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
import "./Trend.css";
import {
  faMobileAlt,
  faLaptop,
  faTabletAlt,
} from "@fortawesome/free-solid-svg-icons";

const categoriesConfig = {
  mobiles: {
    icon: faMobileAlt,
    name: "MOBILES",
    className: "Mobiles",
  },
  tablets: {
    icon: faTabletAlt,
    name: "TABLETS",
    className: "Tablets",
  },
  laptops: {
    icon: faLaptop,
    name: "LAPTOPS",
    className: "Laptops",
  },
  // Add other categories similarly...
};

const Trending = () => {
  const navigate = useNavigate();

  const handleClick = (category) => {
    navigate(`/trending/${category.toLowerCase()}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="row p-4 text-center">
        <h3 className="col-12 trending__heading">
          Explore Trending Tech Products
        </h3>
      </div>
      <section className="Tcards p-3 text-center">
        <article
          className="Tcard Tcard--1"
          onClick={() => handleClick("mobiles")}
        >
          <div className="Tcard__info-hover"></div>
          <div className="Tcard__img" />
          <Link className="Tcard_link">
            <div className="Tcard__img--hover" />
          </Link>
          <div className="Tcard__info">
            <span className="Tcard__category">Mobiles</span>
            <h3 className="Tcard__title">
              Compare Mobile Ratings & Specifications
            </h3>
            <span className="Tcard__by">
              Top
              <Link className="Tcard__author" title="author">
                Trending Phones
              </Link>
            </span>
          </div>
        </article>

        <article
          className="Tcard Tcard--2"
          onClick={() => handleClick("laptops")}
        >
          <div className="Tcard__info-hover"></div>
          <div className="Tcard__img" />
          <Link className="Tcard_link">
            <div className="Tcard__img--hover" />
          </Link>
          <div className="Tcard__info">
            <span className="Tcard__category">Laptops</span>
            <h3 className="Tcard__title">Explore Top Rated Laptops & Specs</h3>
            <span className="Tcard__by">
              Top
              <Link className="Tcard__author" title="author">
                Trending Laptops
              </Link>
            </span>
          </div>
        </article>
        <article
          className="Tcard Tcard--3"
          onClick={() => handleClick("tablets")}
        >
          <div className="Tcard__info-hover"></div>
          <div className="Tcard__img" />
          <Link className="Tcard_link">
            <div className="Tcard__img--hover" />
          </Link>
          <div className="Tcard__info">
            <span className="Tcard__category">Tablets</span>
            <h3 className="Tcard__title">
              Find Best Tablets by Features & Scores
            </h3>
            <span className="Tcard__by">
              Top
              <Link className="Tcard__author" title="author">
                Trending Tablets
              </Link>
            </span>
          </div>
        </article>
      </section>

      <div className="my-list-container t-product mb-3 mt-4">
        {Object.keys(categoriesConfig).map((key) => (
          <div
            key={key}
            className={`my-list-item ${categoriesConfig[key].className}`}
            onClick={() => handleClick(key)}
          >
            <FontAwesomeIcon icon={categoriesConfig[key].icon} />
            <span className="ml-3">{categoriesConfig[key].name}</span>
          </div>
        ))}
      </div>

      <div className="mt-5">
        <ProductContainer />
      </div>
    </div>
  );
};

const ProductContainer = () => {
  const [topItems, setTopItems] = useState({
    mobiles: [],
    laptops: [],
    tablets: [],
  });

  useEffect(() => {
    // Fetch top items from an API or use a static source
    const fetchedTopItems = {
      mobiles: [
        {
          id: "6576e3f1f2378c94a9c996ee1",
          name: "Google Pixel 8 Pro",
          image:
            "https://mainprojectimg.s3.amazonaws.com/1-Google Pixel 8 Pro.jpg",
          rating: 8,
          specs: {
            Chipset: "Google Tensor G3 (4 nm)",
            Cpu: "Nona-core (1x3.0 GHz Cortex-X3 & 4x2.45 GHz Cortex-A715 & 4x2.15 GHz Cortex-A510)",
            Gpu: "Immortalis-G715s MC10",
            CardSlot: "No",
            Internal:
              "128GB 12GB RAM, 256GB 12GB RAM, 512GB 12GB RAM, 1TB 12GB RAM",
            Features: "Auto-HDR, panorama",
          },
          link: "/mobiles/google/google-pixel-8-pro",
        },
        {
          id: "6576e3f1f2378c94a9c996ee2",
          name: "Oppo Find N3 Flip",
          image:
            "https://mainprojectimg.s3.amazonaws.com/13-Oppo Find N3 Flip.jpg",
          rating: 9,
          specs: {
            Chipset: "Mediatek MT6985 Dimensity 9200 (4 nm)",
            Cpu: "Octa-core (1x3.05 GHz Cortex-X3 & 3x2.85 GHz Cortex-A715 & 4x1.80 GHz Cortex-A510)",
            Gpu: "Immortalis-G715 MC11",
            CardSlot: "No",
            Internal: "256GB 12GB RAM, 512GB 12GB RAM",
            Features: "HDR, panorama",
          },
          link: "/mobiles/oppo/oppo-find-n3-flip",
        },
      ],
      laptops: [
        {
          id: "6576dcad281ec7002e2ef1021",
          name: "Microsoft Surface Pro Laptop",
          image:
            "https://mainprojectimg.s3.amazonaws.com/64-Microsoft Surface Pro Laptop.jpg",
          rating: 7,
          specs: {
            Core: "Dual Core, 4 Threads",
            RAM: "8 GB DDR3",
            Storage: "256 GB",
            Speed: "2 x 2.6 GHz Cores",
            Resolution: "2736 x 1824 pixels",
            GPU: "Intel HD 620",
            OS: "Windows 10 Professional (64-bit)",
          },
          link: "/laptops/microsoft/microsoft-surface-pro-laptop",
        },
        {
          id: "6576dcad281ec7002e2ef1022",
          name: "Apple MacBook",
          image:
            "https://mainprojectimg.s3.amazonaws.com/128-Apple MacBook MNYJ2HNA Laptop.jpg",
          rating: 9.5,
          specs: {
            Core: "Hexa Core, 12 Threads",
            RAM: "8 GB DDR3",
            Storage: "512 GB",
            Speed: "2.6GHz 6-core 9th-generation Intel Core i7 processor",
            Resolution: "2304 x 1440 pixels",
            GPU: "Intel Integrated HD 615",
            OS: "Mac OS Sierra (64-bit)",
          },
          link: "/laptops/apple/apple-macbook-mnyj2hna-laptop",
        },
      ],
      tablets: [
        {
          id: "6576f2f254d18e4d977509021",
          name: "Apple iPad Air 2022",
          image:
            "https://mainprojectimg.s3.amazonaws.com/20-Apple iPad Air 2022.jpg",
          rating: 8,
          specs: {
            CPU: "Octa-core (4x3.2 GHz & 4xX.X GHz)",
            GPU: "Apple GPU (8-core graphics)",
            storage: "64GB, 256GB 8GB RAM",
            Weight: "461 g (Wi-Fi) / 462 g (5G) (1.02 lb)",
            Resolution: "1640 x 2360 pixels (~264 ppi density)",
            Dimensions: "240 x 169.5 x 7.5 mm (9.45 x 6.67 x 0.30 in)",
          },
          link: "/tablets/apple/apple-ipad-pro-129-2018-wifi64gb",
        },
        {
          id: "6576f2f254d18e4d977509022",
          name: "Apple iPad Pro 11 2022",
          image:
            "https://mainprojectimg.s3.amazonaws.com/9-Apple iPad Pro 11 2022.jpg",
          rating: 9,
          specs: {
            CPU: "Octa-core",
            GPU: "Apple GPU (10-core graphics)",
            storage:
              "128GB 8GB RAM, 256GB 8GB RAM, 512GB 8GB RAM, 1TB 16GB RAM, 2TB 16GB RAM",
            Weight: "466 g (Wi-Fi), 470 g (5G) (1.03 lb)",
            Resolution: "1668 x 2388 pixels (~265 ppi density)",
            Dimensions: "247.6 x 178.5 x 5.9 mm (9.75 x 7.03 x 0.23 in)",
          },
          link: "/tablets/apple/apple-iPad-Pro-11-2022",
        },
      ],
    };

    setTopItems(fetchedTopItems);
  }, []);

  return (
    <div>
      {Object.keys(topItems).map((category) => (
        <ProductList
          key={category}
          category={category}
          items={topItems[category]}
        />
      ))}
    </div>
  );
};

const ProductList = ({ category, items }) => {
  return (
    <div>
      <h2 style={{ textAlign: "center", color: "blue", fontSize: "24px" }}>
        {category.toUpperCase()}
      </h2>
      {items.map((item) => (
        <ProductCard key={item.id} item={item} />
      ))}
    </div>
  );
};

const ProductCard = ({ item }) => {
  return (
    <Link to={item.link} style={{ textDecoration: "none" }}>
      <div className="product-card1">
        <div className="product-image1">
          <img src={item.image} alt="Product Image" />
        </div>
        <div className="product-content">
          <div className="product-info">
            <div className="product-score">
              <span className="product-score-value">{item.rating}</span>
              <span className="product-score-label">/10</span>
            </div>
            <h2 className="mb-2">{item.name}</h2>
          </div>
          <ul>
            {Object.keys(item.specs).map((key) => (
              <li key={key}>
                {key}: {item.specs[key]}
              </li>
            ))}
          </ul>
          <div className="product-actions">
            <button>Compare</button>
            <button>Like</button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Trending;
