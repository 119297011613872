// GoogleTagManager.js
import { useEffect } from "react";

const GoogleTagManager = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-LB50GBN471";
    document.head.appendChild(script);

    const scriptTag = document.createElement("script");
    scriptTag.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-LB50GBN471');
    `;
    document.head.appendChild(scriptTag);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(scriptTag);
    };
  }, []);

  return null; // This component doesn't render any UI
};

export default GoogleTagManager;





// 2 option


// GoogleTagManager.js
// import { useEffect } from "react";

// const GoogleTagManager = () => {
//   useEffect(() => {
//     const script = document.createElement("script");
//     script.async = true;
//     script.src = "https://www.googletagmanager.com/gtag/js?id=G-LB50GBN471";
//     document.head.appendChild(script);

//     const initScript = document.createElement("script");
//     initScript.innerHTML = `
//       window.dataLayer = window.dataLayer || [];
//       function gtag(){dataLayer.push(arguments);}
//       gtag('js', new Date());
//       gtag('config', 'G-LB50GBN471');
//     `;
//     document.head.appendChild(initScript);

//     return () => {
//       document.head.removeChild(script);
//       document.head.removeChild(initScript);
//     };
//   }, []); // Runs only once on mount

//   return null;
// };

// export default GoogleTagManager;
