import React, { useState, useEffect } from "react";
import { fetchfilterAllData } from "../../Service/featureService";
import { useParams, useNavigate } from "react-router-dom";
import { getBrandData } from "../../Service/Api";
import NewLayoutProduct from "../../Components/Home/Products/NewLayoutProduct";

const MobileFinder = () => {
  const [phones, setPhones] = useState([]);
  const [filters, setFilters] = useState({
    category: "mobiles",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [brands, setBrand] = useState([]);

  // Fetch all the brand name
  const FetchBrandName = async () => {
    setLoading(true);
    try {
      const response = await getBrandData(filters.category);
      setBrand(response.data);
      console.log(brands);
    } catch (error) {
      console.error("Error fetching phones:", error);
      setBrand([]);
    } finally {
      setLoading(false);
    }
  };

  const onClickBtn = (category, brand, slug) => {
    navigate(`/${category}/${brand}/${slug}`);
  };

  // Handle input changes to update filters
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  // Fetch all phones from API and apply filters
  const fetchPhones = async () => {
    setLoading(true);
    try {
      // const query = { category: "mobiles" , ...filters };
      // console.log("Query:", query);
      const response = await fetchfilterAllData(filters);
      const filteredPhones = response.data || [];
      // console.log("Filtered Phones:", filteredPhones);
      setPhones(filteredPhones);
    } catch (error) {
      console.error("Error fetching phones:", error);
      setPhones([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchBrandName();
  }, [filters]);

    // Check if any meaningful filter is selected
    const isButtonDisabled = Object.values(filters).every(
      (value) => value === "" || value === "mobiles"
    );

  return (
    <div className="container mx-auto border bg-white rounded-xl p-4 my-5">
      <h3 className="text-4xl font-bold mb-6 text-[#0A6075] text-center">
        Find Your Mobile
      </h3>

      {/* Filters */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">Brand Name</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1 max-h-[400px] overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:bg-gray-400
  [&::-webkit-scrollbar-thumb]:bg-gray-600
  dark:[&::-webkit-scrollbar-track]:bg-neutral-900
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-700"
            name="brand"
            onChange={handleChange}
          >
            <option className="p-2" value="">
              Search with brand name...
            </option>
            {brands.map((brandname) => (
              <option
                className="p-2"
                key={brandname.brand}
                value={brandname.brand}
              >
                {brandname.brand.toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        {/* filter by network */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">
            Network type
          </h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name="3g"
            onChange={handleChange}
          >
            <option value="">3G</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name="4g"
            onChange={handleChange}
          >
            <option value="">4G</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name="5g"
            onChange={handleChange}
          >
            <option value="">5G</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* filter by Display REsolution */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">Display :</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name="curved_display"
            onChange={handleChange}
          >
            <option value="">Curved Display</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name=""
            onChange={handleChange}
          >
            <option value="">HD</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name="HD"
            onChange={handleChange}
          >
            <option value="">Full HD</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* filter by sim type */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">Sim Slots :</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name=""
            onChange={handleChange}
          >
            <option value="">Select Sim</option>
            <option value="Single">Single Sim</option>
            <option value="dual_sim">Dual Sim</option>
          </select>
        </div>

        {/* filter by card slot */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">Card Slot:</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name="card_slot"
            onChange={handleChange}
          >
            <option value="">Select Slot</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* filter by jack */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">5mm Jack:</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name="5mm_jack"
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* filter by Gps */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">GPS:</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name=""
            onChange={handleChange}
          >
            <option value="">GALILEO</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name=""
            onChange={handleChange}
          >
            <option value="">A-GPS</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name=""
            onChange={handleChange}
          >
            <option value="">GLONASS</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* filter by radio */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">Radio:</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name={"radio" || "fm_radio"}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* filter by nfc */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">NFC:</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name={"nfc"}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* filter by RAM */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">RAM:</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name="ram"
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="4 GB">4GB</option>
            <option value="6 GB">6GB</option>
            <option value="8 GB">8GB</option>
            <option value="12 GB">12GB</option>
          </select>
        </div>
        {/* filter by storage */}
        <div>
          <h3 className="font-semibold sm:text-xl text-md mb-2">Storage:</h3>
          <select
            className="flex-1 p-2 border-1 border-gray-500 rounded-lg bg-gray-300 m-1"
            name="storage"
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="64 GB">64GB</option>
            <option value="128 GB">128GB</option>
            <option value="512 GB">512GB</option>
            <option value="1 TB">1TB</option>
          </select>
        </div>

        {/* <select name="chipset" onChange={handleChange}>
          <option value="">All Chipsets</option>
          <option value="Snapdragon">Snapdragon</option>
          <option value="Mediatek">Mediatek</option>
        </select>

        <select name="ram" onChange={handleChange}>
          <option value="">Select RAM</option>
          <option value="4 GB">4GB</option>
          <option value="6 GB">6GB</option>
          <option value="8 GB">8GB</option>
        </select>

        <select name="storage" onChange={handleChange}>
          <option value="">Select Storage</option>
          <option value="64 GB">64GB</option>
          <option value="128 GB">128GB</option>
          <option value="256 GB">256GB</option>
        </select> */}
      </div>

      <button
        className={`w-full px-4 py-2 rounded-lg ${
          isButtonDisabled
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-[#0A6075] hover:bg-[#064c5e] text-white"
        }`}
        onClick={fetchPhones}
        disabled={isButtonDisabled}
      >
        Show Matching Phones
      </button>

      {/* Display Matching Phones */}
      <div className="p-1 w-full my-3 text-center">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className=" flex felx flex-wrap justify-evenly items-center">
            {phones.map((item) => (
              <NewLayoutProduct
                _id={item._id}
                img={item.img}
                productName={item.model_name}
                slug={item.slug}
                price="44.00"
                category={item.category}
                brand={item.brand}
                badge={true}
                des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileFinder;
