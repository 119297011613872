import React from "react";

const Heading = ({ heading }) => {
  return (
    <div>
      <p className="text-sm font-semibold text-gray-600">This Month</p>
      <h2 className="text-2xl font-bold">New Arrivals</h2>
    </div>
  );
};

export default Heading;
