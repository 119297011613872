import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import { ReadSingleNews } from "../../../Service/newsService";
import { Helmet } from "react-helmet";

export default function NewsDetails() {
  const { slug } = useParams();
  console.log("slug slug",slug);
  

  const [data, setData] = useState([]);
  const [EditerContent, setEditerContent] = useState("");
  const [metaTag, setmetaTag] = useState("");

  const fetchData = async () => {
    try {
      const response = await ReadSingleNews(slug);
      if (response.success === true) {
        setData(response.results);
        setEditerContent(response.results.content);
        setmetaTag(response.results.meta);
      }
      console.log(response);
      
    } catch (error) {
      console.log("Error in fetching data", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [slug]);

  useEffect(() => {
    const adjustIframeHeight = () => {
      const iframe = document.getElementById("content-iframe");
      if (iframe && iframe.contentWindow) {
        iframe.style.height = "auto"; // Reset height to auto to recalculate
        const doc = iframe.contentWindow.document;
        const newHeight = doc.documentElement.scrollHeight;
        iframe.style.height = `${newHeight}px`;
      }
    };

    if (EditerContent) {
      const iframe = document.getElementById("content-iframe");
      if (iframe) {
        iframe.onload = adjustIframeHeight;
        // Adjust height again in case content is loaded after onLoad event
        setTimeout(adjustIframeHeight, 500);
      }
    }

    window.addEventListener("resize", adjustIframeHeight);

    return () => {
      window.removeEventListener("resize", adjustIframeHeight);
    };
  }, [EditerContent]);

  const handleImageLoad = () => {
    if (window.pagespeed && window.pagespeed.CriticalImages) {
      window.pagespeed.CriticalImages.checkImageForCriticality(this);
    }
  };

  return (
    <div>
      {/* <Helmet>
        <title>{metaTag?.title}</title>
        <meta name="description" content={metaTag?.description} />
        {metaTag?.keywords && (
          <meta name="keywords" content={metaTag.keywords.join(", ")} />
        )}
      </Helmet> */}
      <div className="container mt-4">
        <div className="cs-blog-detail">
          <h2 className="Hed-text p-4">
            {" "}
            <i className="bi bi-newspaper p-3"></i>
            {data.title}
          </h2>
          <div className="cs-main-post">
            <figure>
              <img
                onLoad={handleImageLoad}
                data-pagespeed-url-hash={2714250504}
                alt="noimage"
                src={data.img}
              />
            </figure>
          </div>
          <div className="cs-post-title">
            <div className="cs-author"></div>
            <div className="post-option">
              <span className="post-date">
                <a href="#">
                  <i className="cs-color icon-calendar6"></i>
                  {data.createdAt}
                </a>
              </span>
            </div>
          </div>
          <div className="cs-post-option-panel">
            <div className="rich-editor-text">
              <p>{data.description}</p>
            </div>
          </div>

          <iframe
            id="content-iframe"
            title="Jodit Content"
            frameBorder="0"
            style={{
              width: "100%",
              height: "auto", // Adjust height dynamically
              overflow: "hidden", // Hide scrollbar
            }}
            srcDoc={
              EditerContent
                ? DOMPurify.sanitize(`
                <style>
                  body { overflow: hidden; margin: 0; padding: 0; }
                  html { overflow: hidden; }
                </style>
                ${EditerContent}
              `)
                : ""
            }
            onLoad={() => {
              const iframe = document.getElementById("content-iframe");
              if (iframe) {
                iframe.style.height =
                  iframe.contentWindow.document.documentElement.scrollHeight +
                  "px";
              }
            }}
          />
          <div className="cs-post-option-panel"></div>
          <div className="cs-tags">
            <div className="tags"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
