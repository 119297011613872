import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { addToWishlist } from "../../../Service/wishlistService";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../DesignLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import { FaStar, FaHeart, FaEye } from "react-icons/fa";
import { toast } from "react-toastify";

const NewLayoutProduct = (props) => {
  const navigate = useNavigate();
  const productItem = props;
  // console.log("product details",productItem);

  const handleProductDetails = () => {
    navigate(
      `/${productItem.category}/${productItem.brand}/${productItem.slug}`,
      {
        state: {
          item: productItem,
        },
      }
    );
  };

  // wishlist
  // Handle adding to wishlist logic
  async function handleAddToWishlist(product_Id, slug) {
    try {
      if (!product_Id || !slug) {
        toast.error("Product information is missing.", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      const token = localStorage.getItem("accessToken");
      if (!token) {
        toast.info("Please log in to add products to your wishlist.", {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/login");
        return;
      }

      const response = await addToWishlist(token, product_Id, slug);

      if (response.statusCode === 409) {
        toast.warn(response.message, { position: toast.POSITION.TOP_CENTER });
      } else if (response.statusCode === 200) {
        toast.success(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(response.message || "An error occurred.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
      toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
    }
  }

  return (
    <div
      key={props._id}
      className="bg-white border-grey-500 flex flex-col justify-around items-center p-2 rounded-lg border border-gray-200 cursor-pointer w-60 h-60 m-3 hover:shadow-2xl hover:-translate-y-1 transition-all ease-in-out 0.8s"
      onClick={handleProductDetails}
    >
      <div className="relative w-[80%] h-[12rem] flex flex-col justify-center border-b border-gray-300 items-center">
        <Image className="w-auto h-[98%]" imgSrc={props.img} />
        <div className="absolute top-1 right-[-1.55rem] flex space-x-2">
          <FaHeart
            onClick={() => handleAddToWishlist(props._id, props.slug)}
            className="text-gray-500 hover:text-red-500 cursor-pointer"
          />
          <FaEye
            onClick={handleProductDetails}
            className="text-gray-500 hover:text-blue-500 cursor-pointer"
          />
        </div>
      </div>
      <div className="w-full text-center overflow-ellipsis">
        <h3 className="text-md font-semibold">{props.productName}</h3>
      </div>
    </div>
  );
};

export default NewLayoutProduct;
