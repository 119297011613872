import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBrandData } from "../Service/Api";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet"; // Import Helmet

const CategoryPage = () => {
  const { category } = useParams(); // Retrieve the category name from URL
  const [brands, setBrands] = useState([]);
  const [count, setCount] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);

    const fetchBrands = async () => {
      try {
        const data = await getBrandData(category);
        if (data.success) {
          setCount(data.totalCount);
          const sortedBrands = data.data.sort((a, b) =>
            a.brand.localeCompare(b.brand)
          );
          setBrands(sortedBrands);
        } else {
          console.error("Failed to fetch brands");
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, [category]);

  const variant = {
    visible: { scale: 1 },
    hidden: { scale: 0 },
  };

  return (
    <>
      {/* Helmet to manage the <head> section */}
      <Helmet>
        <title>{`Category: ${category
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}`}</title>
        <link
          rel="canonical"
          href={`${window.location.origin}/${category}`}
        />
      </Helmet>

      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.25 }}
      >
        <div>
          <h1 className="text-2xl font-bold text-center p-10">
            Category:{" "}
            {category
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </h1>
        </div>
        <h2 className="p-3 w-[80%] m-auto ">
          {/* <h2>Total Brands : {count}</h2> */}
        </h2>

        <div>
          <section
            id="Projects"
            className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5"
          >
            {brands.map((brand) => (
              <motion.div
                initial="hidden"
                whileInView="visible"
                variants={variant}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0, delay: 0.08 }}
                viewport={{ once: true }}
                whileHover={{ scale: 1.1 }}
                className="w-72 bg-white shadow-xl rounded-xl duration-500 hover:scale-105 hover:shadow-xl cursor-pointer"
                key={brand.id}
                onClick={() => {
                  navigate(`/${category}/${brand.brand}`);
                }}
              >
                <div>
                  <div className="h-[200px] w-[230px] m-auto overflow-hidden">
                    <img
                      src={brand.imgUrl}
                      alt={brand.brand}
                      className="object-contain rounded-t-xl"
                    />
                  </div>
                  <div className="px-4 py-3 w-72 m-auto">
                    <p className="text-lg font-bold text-black truncate block capitalize text-center">
                      {brand.brand}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </section>
        </div>
      </motion.div>
    </>
  );
};

export default CategoryPage;
