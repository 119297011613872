import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { MdMoreVert } from "react-icons/md";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../../Service/userService";
import { toast, ToastContainer } from "react-toastify";
import { FaHeart } from "react-icons/fa";
import { FaSearch, FaUser, FaCaretDown } from "react-icons/fa";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isLoggedIn = !!localStorage.getItem("accessToken");
  const [showUser, setShowUser] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutsideUserDropdown = (e) => {
      if (!e.target.closest(".user-dropdown")) {
        setShowUser(false);
      }
    };

    if (showUser) {
      document.addEventListener("click", handleClickOutsideUserDropdown);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsideUserDropdown);
    };
  }, [showUser]);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await logoutUser(token);
      if (response.success === true) {
        toast.warn(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("accessToken");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bg-blue-500 w-full">
      <nav className="fixed z-999 top-0 p-2 flex justify-between items-center border bg-[#EDF7FA] h-[80px] w-[100%] m-auto">
        <Link className="text-3xl p-4 font-bold leading-none" to="/">
          {/* You can replace this with an actual image or a logo component */}
          <img
            src="https://mainprojectimg.s3.amazonaws.com/logo2.png"
            className="w-[60px] sm:w-[75px] h-[60px] sm:h-[75px]"
            alt=""
          />
        </Link>
        <div className="lg:hidden absolute top-15 sm:right-20 right-[4.6rem]">
          <button
            className="navbar-burger flex items-center text-black "
            onClick={toggleMenu}
          >
            <AiOutlineMenu className="block h-4 w-4 fill-current" />
          </button>
        </div>
        <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-4">
          <li>
            <Link
              className="text-sm text-gray-400 hover:text-black hover:no-underline font-bold"
              to="/"
            >
              Home
            </Link>
          </li>
          <li className="text-gray-300">
            <MdMoreVert className="w-4 h-4" />
          </li>
          <li>
            <Link
              className="text-sm text-gray-400 hover:text-black hover:no-underline font-bold"
              to="/trending"
            >
              Trending
            </Link>
          </li>
          <li className="text-gray-300">
            <MdMoreVert className="w-4 h-4" />
          </li>
          <li>
            <Link
              className="text-sm text-gray-400 hover:text-black hover:no-underline font-bold"
              to="/comparison"
            >
              Compare
            </Link>
          </li>
          <li className="text-gray-300">
            <MdMoreVert className="w-4 h-4" />
          </li>
          <li>
            <Link
              className="text-sm flex text-gray-400 hover:text-black hover:no-underline font-bold"
              to="/mobilefinder"
            >
              <p className="whitespace-nowrap">Mobile Finder</p>
            </Link>
          </li>
          <li className="text-gray-300">
            <MdMoreVert className="w-4 h-4" />
          </li>
          <li>
            <Link
              className="text-sm text-gray-400 hover:text-black hover:no-underline font-bold"
              to="/news"
            >
              News
            </Link>
          </li>
          <li className="text-gray-300">
            <MdMoreVert className="w-4 h-4" />
          </li>
          <li>
            <Link
              className="text-sm text-gray-400 hover:text-black hover:no-underline font-bold"
              to="/coming"
            >
              Chasms
            </Link>
          </li>
        </ul>

        {isLoggedIn ? (
          <>
            <div className="lg:inline-block lg:ml-auto lg:mr-3 py-2 px-4 rounded-md ">
              <div
                onClick={() => setShowUser(!showUser)}
                className="flex user-dropdown"
              >
                <FaUser />
                <FaCaretDown />
              </div>
              {showUser && (
                <div className="flex flex-col text-center justify-center overflow-hidden items-center absolute top-12 right-7 border-grey-500 border-1 bg-white w-20 rounded-md ">
                  <li
                    className="p-1 list-none rounded-md hover:bg-[#0A6075] hover:text-white w-full "
                    onClick={handleLogout}
                  >
                    Logout
                  </li>
                  <hr />
                  <Link
                    className="p-1 list-none rounded-md hover:bg-[#0A6075] hover:text-white w-full hover:no-underline"
                    to="/profile"
                  >
                    Profile
                  </Link>
                  <hr />
                  <Link
                    className="p-1 mx-1 list-none rounded-md hover:bg-[#0A6075] flex justify-center text-center hover:text-white w-full hover:no-underline"
                    to="/favourite"
                  >
                    <FaHeart />
                  </Link>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <Link
              className="hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-3 bg-gray-50 border  hover:bg-[#0A6075] hover:no-underline hover:text-white text-sm text-gray-900 font-bold rounded-xl transition duration-200"
              to="/login"
            >
              Sign In
            </Link>
            <Link
              className="hidden lg:inline-block py-2 px-3 bg-[#0A6075] hover:bg-[#064c5e] hover:no-underline text-sm text-white font-bold rounded-xl transition duration-200"
              to="signup"
            >
              Sign up
            </Link>
          </>
        )}
      </nav>
      {isMenuOpen && (
        <div className="navbar-menu z-999 relative">
          <div
            className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"
            onClick={toggleMenu}
          ></div>

          <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
            <div className="flex items-center mb-8">
              <Link className="mr-auto text-3xl font-bold leading-none" to="#">
                {/* Logo */}
                <img
                  src="https://mainprojectimg.s3.amazonaws.com/logo2.png"
                  className="w-[65px] h-[65px]"
                  alt=""
                />
              </Link>
              <button className="navbar-close" onClick={toggleMenu}>
                <AiOutlineClose className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" />
              </button>
            </div>
            <div>
              <ul>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-black hover:no-underline rounded"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-black hover:no-underline rounded"
                    to="/trending"
                  >
                    Trending
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-black hover:no-underline rounded"
                    to="/comparison"
                  >
                    Compare
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-black hover:no-underline rounded"
                    to="/mobilefinder"
                  >
                    Mobile Finder
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-black hover:no-underline rounded"
                    to="/news"
                  >
                    News
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-black hover:no-underline rounded"
                    to="/coming"
                  >
                    Chasms
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-auto">
              {isLoggedIn ? null : (
                <div className="pt-6">
                  <Link
                    className="block px-4 py-3 mb-3 text-xs text-center font-semibold leading-none hover:bg-[#0A6075] hover:no-underline hover:text-white rounded-xl"
                    to="/login"
                  >
                    Sign in
                  </Link>
                  <Link
                    className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-[#0A6075] hover:bg-[#064c5e] hover:no-underline rounded-xl"
                    to="signup"
                  >
                    Sign Up
                  </Link>
                </div>
              )}
              <p className="my-4 text-xs text-center text-gray-400">
                <span>Copyright © 2021</span>
              </p>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Navbar;
