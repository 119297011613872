// import React, { useEffect, useState, useCallback } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { DynamicSearch } from "../Service/searchService";

// const excludeBasedOnModelName = (slug, category) => {
//   const modelNameLower = slug;
//   if (
//     (category === "KEYBOARD" && modelNameLower.includes("laptop")) ||
//     (category === "MOBILES" &&
//       (modelNameLower.includes("watch") || modelNameLower.includes("pad")))
//   ) {
//     return true;
//   } 
//   return false;
// };


// const SearchResults = () => {
//   const location = useLocation();
//   const searchQuery = new URLSearchParams(location.search).get("query");
//   const navigate = useNavigate();

//   const [searchResults, setSearchResults] = useState([]);
//   const [displayResults, setDisplayResults] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [totalMatchResult, setTotalMatchResult] = useState(0);
//   const [itemsToShow, setItemsToShow] = useState(20);




//   useEffect(() => {
//     const searchQuery = new URLSearchParams(location.search).get("query");
//     console.log("Search Query:", searchQuery); // Check the value of searchQuery
//   }, [location.search]);


//   useEffect(() => {
//     setItemsToShow(20); // Reset items to show every time the search query changes
//   }, [searchQuery]);

//   useEffect(() => {
//     const fetchData = async () => {
//       //  if (!searchQuery || searchQuery === "null") {
//       //    setError("Invalid search query"); // Handle invalid query
//       //    return;
//       //  }
//       try {
//         setLoading(true);
//         setError(null); // Reset error state before new fetch
//         // setError(null); // Reset error state before new fetch
//         const response = await DynamicSearch(searchQuery);
//         if (response.status === "success") {
//           const uniqueModelNames = new Set();
//           const filteredResults = response.results.filter((product) => {
//             const isDuplicate = uniqueModelNames.has(product.slug);
//             if (
//               !isDuplicate &&
//               !excludeBasedOnModelName(product.slug, product.category)
//             ) {
//               uniqueModelNames.add(product.slug);
//               return true;
//             }
//             return false;
//           });
//           setSearchResults(filteredResults);
//           setTotalMatchResult(filteredResults.length);
//         } else {
//           setError(`Error: ${response.message || "Failed to fetch results"}`); // Provide specific error message
//         }
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setError(`Error: ${error.message || "Failed to fetch results"}`);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [searchQuery]);

//   useEffect(() => {
//     setDisplayResults(searchResults.slice(0, itemsToShow));
//   }, [itemsToShow, searchResults]);

//   const navigateToProduct = useCallback(
//     (selectedProduct) => {
//       navigate(
//         `/${selectedProduct.category}/${selectedProduct.brand}/${selectedProduct.slug}`
//       );
//     },
//     [navigate]
//   );

//   const loadMoreItems = () => {
//     setItemsToShow((prev) => prev + 20);
//   };

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-12">
//           <div className="row p-4">
//             <div className="col-6 brand-heading">
//               <h2>
//                 Search Results for:
//                 <b style={{ color: "#0a6075" }}>{searchQuery}</b>
//               </h2>
//             </div>
//             <div className="col-6 text-end brand-heading">
//               <h2>
//                 Total Search Items:
//                 <b style={{ color: "#0a6075" }}>{totalMatchResult}</b>
//               </h2>
//             </div>
//           </div>
//           {loading ? (
//             <p>Loading...</p>
//           ) : error ? (
//             <p>{error}</p>
//           ) : displayResults.length === 0 ? (
//             <p>No data found.</p>
//           ) : (
//             <>
//               <div className="row">
//                 {displayResults.map((item, index) => (
//                   <div
//                     key={index}
//                     className="grid col-lg-4 col-md-3 col-sm-6"
//                     onClick={() => navigateToProduct(item)}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <div className="grid wishlist-item mb-5">
//                       <img
//                         className="card-img-top"
//                         src={item.img}
//                         alt="Product"
//                         style={{ height: "200px", objectFit: "contain" }}
//                       />
//                       <div className="text-center">
//                         <h5 className="card-title">
//                           <b>{item.model_name}</b>
//                         </h5>
//                         <div className="col-12 row w-100 mt-2">
//                           <hr />
//                           <div
//                             className="col-6 mb-0 p-2"
//                             style={{ color: "#0a6075" }}
//                           >
//                             <b>Compare</b>
//                           </div>
//                           <div className="col-6 mb-0 p-2 prd-text">
//                             <b>Favourite</b>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//               {totalMatchResult > displayResults.length && (
//                 <div className="text-center mt-2 mb-4">
//                   <button className="btn load" onClick={loadMoreItems}>
//                     Load More <i className="fa fa-angle-down"></i>
//                   </button>
//                 </div>
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SearchResults;


// import React, { useEffect, useState, useCallback } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { DynamicSearch } from "../Service/searchService";

// const excludeBasedOnModelName = (slug, category) => {
//   const modelNameLower = slug;
//   if (
//     (category === "KEYBOARD" && modelNameLower.includes("laptop")) ||
//     (category === "MOBILES" &&
//       (modelNameLower.includes("watch") || modelNameLower.includes("pad")))
//   ) {
//     return true;
//   }
//   return false;
// };

// const SearchResults = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   // Retrieve search query from sessionStorage or URL
//   const [searchQuery, setSearchQuery] = useState(() => {
//     return (
//       new URLSearchParams(location.search).get("query") ||
//       sessionStorage.getItem("searchQuery") || // Retrieve from sessionStorage
//       ""
//     );
//   });

//   const [searchResults, setSearchResults] = useState([]);
//   const [displayResults, setDisplayResults] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [totalMatchResult, setTotalMatchResult] = useState(0);
//   const [itemsToShow, setItemsToShow] = useState(20);

//   // Save search query to sessionStorage when it changes
//   useEffect(() => {
//     if (searchQuery) {
//       sessionStorage.setItem("searchQuery", searchQuery);
//     }
//   }, [searchQuery]);

//   useEffect(() => {
//     setItemsToShow(20); // Reset items to show every time the search query changes
//   }, [searchQuery]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);
//         setError(null); // Reset error state before new fetch

//         const response = await DynamicSearch(searchQuery);
//         if (response.status === "success") {
//           const uniqueModelNames = new Set();
//           const filteredResults = response.results.filter((product) => {
//             const isDuplicate = uniqueModelNames.has(product.slug);
//             if (
//               !isDuplicate &&
//               !excludeBasedOnModelName(product.slug, product.category)
//             ) {
//               uniqueModelNames.add(product.slug);
//               return true;
//             }
//             return false;
//           });
//           setSearchResults(filteredResults);
//           setTotalMatchResult(filteredResults.length);
//         } else {
//           setError(`Error: ${response.message || "Failed to fetch results"}`);
//         }
//       } catch (error) {
//         console.error("Error fetching search results:", error);
//         setError(`Error: ${error.message || "Failed to fetch results"}`);
//       } finally {
//         setLoading(false);
//       }
//     };

//     // Only fetch if there is a valid searchQuery
//     if (searchQuery) {
//       fetchData();
//     }
//   }, [searchQuery]);

//   useEffect(() => {
//     setDisplayResults(searchResults.slice(0, itemsToShow));
//   }, [itemsToShow, searchResults]);

//   const navigateToProduct = useCallback(
//     (selectedProduct) => {
//       navigate(
//         `/${selectedProduct.category}/${selectedProduct.brand}/${selectedProduct.slug}`
//       );
//     },
//     [navigate]
//   );

//   const loadMoreItems = () => {
//     setItemsToShow((prev) => prev + 20);
//   };

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-12">
//           <div className="row p-4">
//             <div className="col-6 brand-heading">
//               <h2>
//                 Search Results for:
//                 <b style={{ color: "#0a6075" }}>
//                   {searchQuery
//                     .split("-")
//                     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//                     .join(" ")}
//                 </b>
//               </h2>
//             </div>
//             <div className="col-6 text-end brand-heading">
//               <h2>
//                 Total Search Items:
//                 <b style={{ color: "#0a6075" }}>{totalMatchResult}</b>
//               </h2>
//             </div>
//           </div>
//           {loading ? (
//             <p>Loading...</p>
//           ) : error ? (
//             <p>{error}</p>
//           ) : displayResults.length === 0 ? (
//             <p>No data found.</p>
//           ) : (
//             <>
//               <div className="row">
//                 {displayResults.map((item, index) => (
//                   <div
//                     key={index}
//                     className="grid col-lg-4 col-md-3 col-sm-6"
//                     onClick={() => navigateToProduct(item)}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <div className="grid wishlist-item mb-5">
//                       <img
//                         className="card-img-top"
//                         src={item.img}
//                         alt="Product"
//                         style={{ height: "200px", objectFit: "contain" }}
//                       />
//                       <div className="text-center">
//                         <h5 className="card-title">
//                           <b>{item.model_name}</b>
//                         </h5>
//                         <div className="col-12 row w-100 mt-2">
//                           <hr />
//                           <div
//                             className="col-6 mb-0 p-2"
//                             style={{ color: "#0a6075" }}
//                           >
//                             <b>Compare</b>
//                           </div>
//                           <div className="col-6 mb-0 p-2 prd-text">
//                             <b>Favourite</b>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//               {totalMatchResult > displayResults.length && (
//                 <div className="text-center mt-2 mb-4">
//                   <button className="btn load" onClick={loadMoreItems}>
//                     Load More <i className="fa fa-angle-down"></i>
//                   </button>
//                 </div>
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SearchResults;


import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DynamicSearch } from "../Service/searchService";

const excludeBasedOnModelName = (slug, category) => {
  const modelNameLower = slug;
  if (
    (category === "keyboard" && modelNameLower.includes("laptop")) ||
    (category === "mobiles" &&
      (modelNameLower.includes("watch") || modelNameLower.includes("pad")))
  ) {
    return true;
  }
  return false;
};

const SearchResults = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Retrieve search query from URL
  const [searchQuery, setSearchQuery] = useState(() => {
    return new URLSearchParams(location.search).get("query") || "";
  });

  const [searchResults, setSearchResults] = useState([]);
  const [displayResults, setDisplayResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalMatchResult, setTotalMatchResult] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(20);

  // Detects when the location.search changes (the query string in the URL)
  useEffect(() => {
    const query = new URLSearchParams(location.search).get("query");
    setSearchQuery(query || "");
  }, [location.search]);

  useEffect(() => {
    setItemsToShow(20); // Reset items to show every time the search query changes
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      if (!searchQuery) return;

      try {
        setLoading(true);
        setError(null); // Reset error state before new fetch

        const response = await DynamicSearch(searchQuery);
        if (response.status === "success") {
          const uniqueModelNames = new Set();
          const filteredResults = response.results.filter((product) => {
            const isDuplicate = uniqueModelNames.has(product.slug);
            if (
              !isDuplicate &&
              !excludeBasedOnModelName(product.slug, product.category)
            ) {
              uniqueModelNames.add(product.slug);
              return true;
            }
            return false;
          });
          setSearchResults(filteredResults);
          setTotalMatchResult(filteredResults.length);
        } else {
          setError(`Error: ${response.message || "Failed to fetch results"}`);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setError(`Error: ${error.message || "Failed to fetch results"}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchQuery]);

  useEffect(() => {
    setDisplayResults(searchResults.slice(0, itemsToShow));
  }, [itemsToShow, searchResults]);

  const navigateToProduct = useCallback(
    (selectedProduct) => {
      navigate(
        `/${selectedProduct.category}/${selectedProduct.brand}/${selectedProduct.slug}`
      );
    },
    [navigate]
  );

  const loadMoreItems = () => {
    setItemsToShow((prev) => prev + 20);
  };

  return (
    <div className="container -z-10">
      <div className="row">
        <div className="col-12">
          <div className="row p-4">
            <div className="col-6 brand-heading">
              <h2>
                Search Results for:
                <b style={{ color: "#0a6075" }}>
                  {searchQuery
                    .split("-")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </b>
              </h2>
            </div>
            <div className="col-6 text-end brand-heading">
              <h2>
                Total Search Items:
                <b style={{ color: "#0a6075" }}>{totalMatchResult}</b>
              </h2>
            </div>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : displayResults.length === 0 ? (
            <p>No data found.</p>
          ) : (
            <>
              <div className="row">
                {displayResults.map((item, index) => (
                  <div
                    key={index}
                    className="grid z-0 col-lg-4 col-md-3 col-sm-6"
                    onClick={() => navigateToProduct(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="grid wishlist-item mb-5">
                      <img
                        className="card-img-top"
                        src={item.img}
                        alt="Product"
                        style={{ height: "200px", objectFit: "contain" }}
                      />
                      <div className="text-center">
                        <h5 className="card-title">
                          <b>{item.model_name}</b>
                        </h5>
                        <div className="col-12 row w-100 mt-2">
                          <hr />
                          <div
                            className="col-6 mb-0 p-2"
                            style={{ color: "#0a6075" }}
                          >
                            <b>Compare</b>
                          </div>
                          <div className="col-6 mb-0 p-2 prd-text">
                            <b>Favourite</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {totalMatchResult > displayResults.length && (
                <div className="text-center mt-2 mb-4">
                  <button className="btn load" onClick={loadMoreItems}>
                    Load More <i className="fa fa-angle-down"></i>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
