import React, { useEffect, useState } from "react";
import { ReadHomeNews } from "../../../Service/newsService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsCard from "./NewsCard";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 655,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const News = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await ReadHomeNews();
      if (response.success === true) {
        setData(response.results);
      }
      // console.log("news responce",response);
    } catch (error) {
      console.log("Error in fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="news-section">
      <div className="flex justify-between">
        <div className="w-1/2">
          <h1 className="md:text-4xl sm:text-2xl text-xl font-bold p-1 m-1">
            Latest <b className="text-[#0A6075]">Gadget News</b> &{" "}
            <b className="text-[#0A6075]">Trends</b>
          </h1>
          <p className="md:text-lg sm:text-md text- p-q m-1">
            Stay updated with the latest gadget news, reviews, and tect trends
            from across the world, delivered daily
          </p>
        </div>
        <Link
          className="text-sm hover:no-underline text-[#0A6075]  "
          to={"/News"}
        >
          <div className="border-[#0A6075] flex p-2 hover:text-white hover:bg-[#0A6075] border-1 rounded-full">
            <p className="p-1 ">
              VIEW ALL NEWS
            </p>{" "}
            <b className=" mt-[0.4rem] text-md">
              <FaArrowRight />
            </b>
          </div>
        </Link>
      </div>

      <div className="row"></div>
      <Slider {...settings}>
        {data?.map((article) => (
          <div className="mt-5 p-3" key={article._id}>
            {/* <Link
              to={`/News/${article.slug}`}
              style={{ textDecoration: "none" }}
            > */}
              <NewsCard article={article} />
            {/* </Link> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default News;
