// import React, { useState, useEffect } from "react";
// import { Top_BrandsforHome } from "../../../Service/homeService";
// import { useParams } from "react-router-dom";
// // Card component for displaying individual products
// const ProductCard = ({ product }) => (
//   <div className="card ">
//     <img src={product.img} alt={product.name} className="card-img-top " />
//     <div className="card-body">
//       <h5 className="card-title p-1">{product.model_name}</h5>
//       <p className="card-text p-1">{product.category}</p>
//       <a href={`/product/${product.slug}`} className="btn btn-primary ">
//         View Details
//       </a>
//     </div>
//   </div>
// );

// export default function BrandPage() {
//   const { brand } = useParams();
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await Top_BrandsforHome(brand);
//         setProducts(response.data); // Adjust based on the structure of your API response
//         console.log(response);
        
//         setLoading(false);
//       } catch (err) {
//         setError(err.message);
//         setLoading(false);
//       }
//     };

//     fetchProducts();
//   }, []);

  



//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   return (
//     <div className="container">
//       <h1>Product List</h1>
//       <div className="row">
//         {products.map((product) => (
//           <div key={product._id} className="col-md-4">
//             <ProductCard product={product} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }


// import React, { useState, useEffect } from "react";
// import { Top_BrandsforHome } from "../../../Service/homeService";
// import { useNavigate, useParams } from "react-router-dom";
// import { motion } from "framer-motion";
// import { Helmet } from "react-helmet";



// export default function BrandPage() {
//   const { brand } = useParams();
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await Top_BrandsforHome(brand);
//         setProducts(response.data); // Adjust based on the structure of your API response
//         console.log(response.data[0].brand);
//         setLoading(false);
//       } catch (err) {
//         setError(err.message);
//         setLoading(false);
//       }
//     };

//     fetchProducts();
//   }, [brand]);

// console.log("test",products[0].brand);


//   const handleProductDetails = (product) => {
//     navigate(`/${product.category}/${product.brand}/${product.slug}`, {
//       state: {
//         item: product,
//       },
//     });
//   };

//   window.scroll(0, 0);

//     const variant = {
//       visible: { scale: 1 },
//       hidden: { scale: 0 },
//     };

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   return (
//     <div>
//       {/* <h1>Product List</h1> */}
//       <Helmet>
//         <title>{`${products[0].brand} - Check ${products[0].brand} Complete Details, Full Comparison | gadgetfobia.com`}</title>
//         <meta
//           name="description"
//           content={`${products[0].brand} Compare Here: Check ${products[0].brand} Specs and Reviews. Compare ${products[0].brand} 360 Compare | gadgetfobia.com`}
//         />
//         <meta
//           name="keywords"
//           content={`${products[0].brand}, ${products[0].category}`}
//         />
//         <link
//           rel="canonical"
//           href={`${window.location.origin}/${brand}`}
//         />
//       </Helmet>
//       <motion.div
//         // initial={{ y: 200, opacity: 0 }}
//         // animate={{ y: 0, opacity: 1 }}
//         // transition={{ duration: 0.3, delay: 0.25 }}
//         className="w-[90%] mx-auto grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5 p-2 "
//       >
//         {products.map((product, index) => (
//           <motion.div
//             initial="hidden"
//             whileInView="visible"
//             variants={variant}
//             animate={{ y: 1, opacity: 1 }}
//             transition={{ duration: 0.0, delay: 0.04 }}
//             viewport={{ once: true }}
//             whileHover={{ scale: 1.1 }} // Framer Motion hover scale
//             key={index}
//             className="w-[100%] bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl overflow-hidden cursor-pointer "
//             onClick={() => handleProductDetails(product)}
//           >
//             <div className="p-2 m-auto">
//               <img
//                 src={product.img}
//                 alt={product.name}
//                 className="h-[200px] w-[200px]  rounded-t-xl"
//               />
//               <div className="px-4 py-3 w-72">
//                 <span className="text-gray-400 mr-3 uppercase text-xs">
//                   {product.brand}
//                 </span>
//                 <span className="text-gray-400 mr-3 uppercase text-xs">
//                   {product.category}
//                 </span>

//                 <p className=" text-sm font-bold text-black truncate block capitalize overflow-hidden  w-[200px]">
//                   {product.model_name}
//                 </p>
//                 {/* <div className="flex items-center">
//            <p className="text-lg font-semibold text-black cursor-auto my-3">
//              {product.category}
//            </p>
//          </div> */}
//               </div>
//             </div>
//           </motion.div>
//         ))}
//       </motion.div>
//     </div>
//   );
// }


import React, { useState, useEffect } from "react";
import { Top_BrandsforHome } from "../../../Service/homeService";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

export default function BrandPage() {
  const { brand } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await Top_BrandsforHome(brand);
        if (response && response.data) {
          setProducts(response.data); // Adjust according to your API structure
          console.log(response.data[0]?.brand); // Safe access
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [brand]);

  useEffect(() => {
    // Ensure the page scrolls to the top when the component mounts
    window.scroll(0, 0);
  }, []);

  const handleProductDetails = (product) => {
    navigate(`/${product.category}/${product.brand}/${product.slug}`, {
      state: { item: product },
    });
  };

  const variant = {
    visible: { scale: 1 },
    hidden: { scale: 0 },
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Conditional rendering for Helmet tags to avoid accessing undefined values
  const brandName = products[0]?.brand || "Top Brand";

  return (
    <div>
      <Helmet>
        <title>{`${brandName} - Check ${brandName} Complete Details, Full Comparison | gadgetfobia.com`}</title>
        <meta
          name="description"
          content={`${brandName} Compare Here: Check ${brandName} Specs and Reviews. Compare ${brandName} 360 Compare | gadgetfobia.com`}
        />
        <meta
          name="keywords"
          content={`${brandName}, ${products[0]?.category || ""}`}
        />
        <link rel="canonical" href={`${window.location.origin}/brand/${brand}`} />
      </Helmet>

      <motion.div className="w-[90%] mx-auto grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5 p-2">
        {products.map((product, index) => (
          <motion.div
            key={index}
            initial="hidden"
            whileInView="visible"
            variants={variant}
            transition={{ duration: 0.2, delay: 0.04 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.1 }}
            className="w-[100%] bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl overflow-hidden cursor-pointer"
            onClick={() => handleProductDetails(product)}
          >
            <div className="p-2 m-auto">
              <img
                src={product.img}
                alt={product.name}
                className="h-[200px] w-[200px] rounded-t-xl"
              />
              <div className="px-4 py-3 w-72">
                <span className="text-gray-400 mr-3 uppercase text-xs">
                  {product.brand}
                </span>
                <span className="text-gray-400 mr-3 uppercase text-xs">
                  {product.category}
                </span>
                <p className="text-sm font-bold text-black truncate block capitalize overflow-hidden w-[200px]">
                  {product.model_name}
                </p>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}
