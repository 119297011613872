// // import React from "react";
// // import ReactDOM from "react-dom/client";
// // import { Provider as ReduxProvider } from "react-redux";
// // import { PersistGate } from "redux-persist/integration/react";
// // import "../src/Asset/Css/admin.css";
// // import "slick-carousel/slick/slick.css";
// // import "./index.css";
// // import "../src/Asset/Css/user.css";
// // import { Toaster } from "react-hot-toast";
// // import App from "./App";
// // import { ProductProvider } from "./Context/ProductContext";
// // import { TopBrandProvider } from "./Context/TopBrandContext";
// // import { store as reduxToolkitStore, persistor } from "./redux/store";
// // import store1 from "./Feature/store/store";
// // import { AuthProvider } from "./Context/AuthContext";

// // const root = ReactDOM.createRoot(document.getElementById("root"));
// // root.render(
// //   <ReduxProvider store={reduxToolkitStore}>
// //     <PersistGate loading={null} persistor={persistor}>
// //       <TopBrandProvider>
// //         <ProductProvider>
// //           <AuthProvider>
// //             <ReduxProvider store={store1}>
// //               <Toaster />
// //               <App />
// //             </ReduxProvider>
// //           </AuthProvider>
// //         </ProductProvider>
// //       </TopBrandProvider>
// //     </PersistGate>
// //   </ReduxProvider>
// // );

// // Import necessary modules and libraries for the React app
// import React from "react"; // Core React library
// import ReactDOM from "react-dom/client"; // ReactDOM for rendering the app in the DOM
// import { Provider as ReduxProvider } from "react-redux"; // Redux Provider to integrate the Redux store with React
// import { PersistGate } from "redux-persist/integration/react"; // PersistGate for persisting Redux store across sessions

// // Importing CSS files for the app's styling
// // import "../src/Asset/Css/admin.css"; // Admin-specific CSS styles
// import "slick-carousel/slick/slick.css"; // Styles for Slick carousel (slider)
// import "./index.css"; // General styles for the app
// // import "../src/Asset/Css/user.css"; // User-specific CSS styles


// // Import other necessary components and contexts
// import { Toaster } from "react-hot-toast"; // Notification library for showing toast messages
// import App from "./App"; // Main App component of the application
// import { ProductProvider } from "./Context/ProductContext"; // Context provider for managing product-related state
// import { TopBrandProvider } from "./Context/TopBrandContext"; // Context provider for managing top brands-related state
// import { store as reduxToolkitStore, persistor } from "./redux/store"; // Redux store and persistor (for redux-persist) from the app's redux setup
// import store1 from "./Feature/store/store"; // Another Redux store (possibly for a different feature/module)
// import { AuthProvider } from "./Context/AuthContext"; // Context provider for managing authentication-related state

// // Get the root DOM element where the React app will be rendered
// const root = ReactDOM.createRoot(document.getElementById("root"));

// // Render the React component tree
// root.render(
//   // Provide Redux store to the app using ReduxProvider
//   <ReduxProvider store={reduxToolkitStore}>
//     {/* PersistGate delays the rendering of the app until the persisted state is retrieved */}
//     <PersistGate loading={null} persistor={persistor}>
//       {/* Wrap the app with context providers to manage global states */}
//       <TopBrandProvider> {/* Provides top brand data to the app */}
//         <ProductProvider> {/* Provides product-related data to the app */}
//           <AuthProvider> {/* Provides authentication-related data to the app */}
//             {/* A second Redux store for managing state of a specific feature */}
//             <ReduxProvider store={store1}>
//               {/* Toaster for showing notifications */}
//               <Toaster />
//               {/* Main App component */}
//               <App />
//             </ReduxProvider>
//           </AuthProvider>
//         </ProductProvider>
//       </TopBrandProvider>
//     </PersistGate>
//   </ReduxProvider>
// );


import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick.css";
import "./index.css";
import { Toaster } from "react-hot-toast";
import App from "./App";
import { ProductProvider } from "./Context/ProductContext";
import { TopBrandProvider } from "./Context/TopBrandContext";
import { store as reduxToolkitStore, persistor } from "./redux/store";
import store1 from "./Feature/store/store";
import { AuthProvider } from "./Context/AuthContext";

// Get the root DOM element where the React app will be rendered
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ReduxProvider store={reduxToolkitStore}>
    <PersistGate loading={null} persistor={persistor}>
      <TopBrandProvider>
        <ProductProvider>
          <AuthProvider>
            <ReduxProvider store={store1}>
              <Toaster />
              {/* Remove BrowserRouter and rely on RouterProvider in App.js */}
              <App />
            </ReduxProvider>
          </AuthProvider>
        </ProductProvider>
      </TopBrandProvider>
    </PersistGate>
  </ReduxProvider>
);
