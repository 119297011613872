import React, { createContext, useState, useMemo, useCallback } from "react";
import { fetchfilterAllData } from "../Service/featureService";

// Create the context
export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateProducts = useCallback(async (query) => {
    setLoading(true);
    try {
      const response = await fetchfilterAllData(query);
      console.log("filteralldata fetched: ", query, typeof query)
      // Assume response.data contains an array of products
      if (response.data) {
        // Sort products by release_date in descending order
        const sortedProducts = response.data.sort((a, b) => {
          // Convert "month/year" to a date object for comparison
          const dateA = a.release_date
            ? new Date(`1/${a.release_date}`)
            : new Date(0);
          const dateB = b.release_date
            ? new Date(`1/${b.release_date}`)
            : new Date(0);
          return dateB - dateA; // Sort in descending order
        });
        setProducts(sortedProducts);
        // console.log("short",sortedProducts);
        
      } else {
        setProducts([]);
      }
      setLoading(false);
      setError(null);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, []); // Dependencies for useCallback, if any

  // Memoizing the context value
  const value = useMemo(
    () => ({
      products,
      loading,
      error,
      updateProducts,
    }),
    [products, loading, error, updateProducts]
  );

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};
