import React, { useEffect, useContext, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../Components/PageProps/Breadcrumbs";
import ShopSideNav from "../Components/PageProps/ShopPage/ShopSideNav";
import { ProductContext } from "../Context/ProductContext";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const Shop = () => {
  const { category, brand } = useParams();
  const { products, loading, error, updateProducts } =
    useContext(ProductContext);
  const navigate = useNavigate();
  const [itemsToShow, setItemsToShow] = useState(20);

  console.log(typeof category, category);

  // const productmeta= products

  const productmeta =
    products.length > 0 ? products[0] : { brand: "", category: "" }; 

  // Ensure products update when params change
  useEffect(() => {
    updateProducts({ category, brand });
    window.scrollTo(0, 0);
  }, [category, brand, updateProducts]);

  const handle = (id) => {
    localStorage.setItem("id", JSON.stringify(id));
  };

  const onClickBtn = (category, brand, slug) => {
    navigate(`/${category}/${brand}/${slug}`);
  };

  const excludeBasedOnModelName = useMemo(() => {
    return (modelName, category) => {
      if (!modelName) return false;
      const modelNameLower = modelName.toLowerCase();
      if (category === "keyboard" && modelNameLower.includes("Laptops"))
        return true;
      if (
        category === "mobiles" &&
        (modelNameLower.includes("watch") || modelNameLower.includes("pad"))
      )
        return true;
      if (category === "gaming-mouses" && modelNameLower.includes("monitor"))
        return true;
      if (category === "gaming-mouses" && modelNameLower.includes("Laptops"))
        return true;
      return false;
    };
  }, []);

  const uniqueProducts = useMemo(() => {
    if (!products) return [];
    return products.filter(
      (product, index, self) =>
        index === self.findIndex((t) => t.model_name === product.model_name) &&
        !excludeBasedOnModelName(product.model_name, category)
    );
  }, [products, excludeBasedOnModelName, category]);

  const displayedProducts = useMemo(
    () => uniqueProducts.slice(0, itemsToShow),
    [uniqueProducts, itemsToShow]
  );

  const handleShowMore = () => setItemsToShow((prev) => prev + 20);

  const variant = { visible: { scale: 1 }, hidden: { scale: 0 } };

  return (
    <>
      {/* Helmet section to dynamically set the meta tags */}
      <Helmet>
        <title>{`${productmeta.brand} - Check ${productmeta.brand} Complete Details, Full Comparison | gadgetfobia.com`}</title>
        <meta
          name="description"
          content={`${productmeta.brand} Compare Here: Check ${productmeta.brand} Specs and Reviews. Compare ${productmeta.brand} 360 Compare | gadgetfobia.com`}
        />
        <meta
          name="keywords"
          content={`${productmeta.brand}, ${productmeta.category}`}
        />
        <link
          rel="canonical"
          href={`${window.location.origin}/${category}/${brand}`}
        />
      </Helmet>

      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.25 }}
        className="max-w-container mx-auto px-4"
      >
        <div className="col-12 row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Breadcrumbs title="Products" />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 text-center mt-4 Heading-text">
            <h1 className="mt-4 p-3 prd-hed" style={{ color: "#FFF" }}>
              {brand.toUpperCase().split("-").join(" ")}{" "}
              {category.toUpperCase().split("-").join(" ")}
            </h1>
          </div>
        </div>

        <div className="w-full h-full flex pb-20">
          <div className="w-[20%] lgl:w-[20%] hidden mdl:inline-flex h-full">
            <ShopSideNav category={category} brand={brand} />
          </div>

          <div className="w-full mdl:w-[90%] lgl:w-[90%] h-full flex flex-col gap-10">
            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}

            <div className="grid grid-cols-4 gap-6">
              {displayedProducts.map((product) => (
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  variants={variant}
                  animate={{ y: 1, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.04 }}
                  viewport={{ once: true }}
                  whileHover={{ scale: 1.1 }}
                  key={product._id}
                  className="w-[90%] relative group cursor-pointer"
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  onClick={() =>
                    onClickBtn(product.category, product.brand, product.slug)
                  }
                >
                  <div className="max-w-80 max-h-80 relative overflow-y-hidden">
                    <img
                      onClick={() => handle(product._id)}
                      src={product.img}
                      alt={product.model_name}
                      className="object-contain w-[200px] h-[250px] text-sm m-auto"
                    />
                    <div className="w-full sm:h-12 md:h-24 absolute bg-white -bottom-[130px] group-hover:bottom-0 duration-700">
                      <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
                        <li className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 pb-1 duration-300 w-full">
                          Compare
                        </li>
                        <li className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 pb-1 duration-300 w-full">
                          View Details
                        </li>
                        <li className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 pb-1 duration-300 w-full">
                          Add to Favourite
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
                    <h2 className="hello-rev product-name text-primeColor font-medium text-xs">
                      {product.model_name.split(" ").length > 5
                        ? product.model_name.split(" ").slice(0, 7).join(" ") +
                          "....."
                        : product.model_name}
                    </h2>
                  </div>
                </motion.div>
              ))}
            </div>

            {uniqueProducts.length > 20 &&
              itemsToShow < uniqueProducts.length && (
                <div className="text-center mt-2 mb-4">
                  <button className="btn load" onClick={handleShowMore}>
                    Show More <i className="fa fa-angle-down"></i>
                  </button>
                </div>
              )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Shop;
