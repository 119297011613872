import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const NewsCard = ({ article }) => {
  const truncatedTitle = article
    ? article.title.split(" ").slice(0, 10).join(" ") + "..."
    : "";
  const truncatedDescription = article
    ? article.description.split(" ").slice(0, 10).join(" ") + "..."
    : "";

  return (
    <div className="gap-1 border-1 m-w-[350px] w-full h-[542px] p-4 rounded-2xl border-[#0A6075] overflow-hidden">
      <div className="w-full mb-2 h-auto object-cover overflow-hidden m-auto rounded-2xl">
        <img
          src={article.img}
          alt={article.title}
          className="w-full h-[210px] rounded-2xl object-cover"
        />
      </div>
      <div className="p-2 mt-2 flex flex-col gap-2">
        <p className="text-black text-sm">{article.createdAt}</p>
        <h3 className="text-xl font-semibold text-[#0A6075] cursor-pointer leading-snug overflow-hidden text-ellipsis line-clamp-2">
          {truncatedTitle}
        </h3>
        <p className="text-sm text-black leading-relaxed line-clamp-3">
          {article.description}
        </p>
        <Link
          className="text-sm hover:no-underline text-[#0A6075]"
          to={`/News/${article.slug}`}
        >
          <button className="border-[#0A6075] flex p-2 hover:text-white hover:bg-[#0A6075] border-1 rounded-full">
            <p className="p-1">VIEW ALL NEWS</p>
            <b className="mt-[0.4rem] text-md">
              <FaArrowRight />
            </b>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NewsCard;
