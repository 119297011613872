import React, { useState, useEffect } from "react";
import { top_Mobiles } from "../../Service/homeService";
import NewLayoutProduct from "../../Components/Home/Products/NewLayoutProduct";

// PopularPhones component
const PopularPhones = (props) => {
  const [newData, setNewData] = useState([]); // State to store fetched gadgets
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for error handling

  // Fetch and sort the data from the API
  const fetchData = async () => {
    try {
      const response = await top_Mobiles(); // Fetch data from the API
      setNewData(response.data); // Update state with sorted data
    } catch (error) {
      setError("Error fetching data: " + error.message); // Set error message
    } finally {
      setLoading(false); // Set loading to false after fetching completes
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Utility function to get the first five words of a string
  const getFirstFiveWords = (str) => {
    if (typeof str === "string" && str.trim().length > 0) {
      const words = str.split(" ");
      return words.slice(0, 5).join(" ");
    }
    return str; // Return original string if it's shorter than five words
  };

  // Render loading, error, or the fetched data
  if (loading) return <p>Loading data...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-4 border border-grey-500 my-2 rounded-lg w-[96%] m-auto mt-14">
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-sm font-semibold text-gray-600">Our Products</p>
          <h2 className="text-2xl font-bold">Explore Popular Phones</h2>
        </div>
      </div>
      <div className="flex flex-row justify-evenly align-middle flex-wrap">
        {newData
          ?.filter((item) => getFirstFiveWords(item.model_name) !== null)
          .map((item, index) => {
            // Optional: Provide fallbacks or handle undefined for category and brand
            const productName = getFirstFiveWords(item.model_name);
            const category = item.category ?? "Uncategorized"; // Fallback if undefined
            const brand = item.brand ?? "No Brand"; // Fallback if undefined

            // Proceed to render only if productName exists
            if (productName) {
              return (
                <div key={index} className="px-2">
                  <NewLayoutProduct
                    _id={item._id}
                    img={item.img}
                    productName={productName}
                    slug={item.slug}
                    price="44.00"
                    category={category}
                    brand={brand}
                    badge={true}
                    des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
                  />
                </div>
              );
            }
            return null; // Don't render anything if conditions are not met
          })}
      </div>
    </div>
  );
};

export default PopularPhones;
