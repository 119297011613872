import api from "../Service/apiHelper";
// ###############################################################################

// ###############################################################################
// export const addComment = async (token, content, productId) => {
//   try {
//     if (!token || typeof token !== "string") {
//       throw new Error("Invalid access token provided.");
//     }

//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     };

//     const response = await api.post(
//       `api/v1/feature/addcomment`,
//       {
//         content,
//         productId,
//       },
//       config
//     );

//     return response.data;
//   } catch (error) {
//     // console.error("Error adding comment:", error.message);
//     throw new Error("An error occurred while adding the comment.");
//   }
// };

export const addComment = async (token, content, rating, productId) => {
  try {
    if (!token || typeof token !== "string") {
      throw new Error("Invalid access token provided.");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await api.post(
      `api/v1/feature/addcomment`,
      { content, rating, productId }, // Send rating along with content and productId
      config
    );

    return response.data;
  } catch (error) {
    throw new Error("An error occurred while adding the comment.");
  }
};


// ##############################################################
// export const getComments = async (productId) => {
//   try {
//     // Modify the request URL to include the productId
//     const response = await api.get(
//       `api/v1/feature/getcomment?productId=${productId}`
//     );

//     return response.data;
//   } catch (error) {
//     // console.error("Error fetching comments:", error.message || error.response);
//     throw new Error("An error occurred while fetching comments.");
//   }
// };

export const getComments = async (productId) => {
  try {
    const response = await api.get(
      `api/v1/feature/getcomment?productId=${productId}`
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching comments.");
  }
};

// ##############################################################
export const editComment = async (_id, token) => {
  try {
    const response = await api.post(`api/v1/feature/editcomment/${_id}`);

    return response.data;
  } catch (error) {
    // console.error("Error removing item from wishlist:", error);
    throw new Error(
      "An error occurred while removing the item from the wishlist."
    );
  }
};
// ##############################################################
export const deleteComment = async (_id, token) => {
  try {
    const response = await api.post(`api/v1/feature/deletecomment/${_id}`);

    return response.data;
  } catch (error) {
    console.error("Error removing item from wishlist:", error);
    throw new Error(
      "An error occurred while removing the item from the wishlist."
    );
  }
};
